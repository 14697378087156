import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TabPanel, TabView } from 'primereact/tabview';
import { Tooltip } from 'primereact/tooltip';
import { Badge } from 'primereact/badge';
import Home from './Home';
import Overview from './Overview';
import { setDashboardActiveTabIndex } from './actions/dashboard.action.creator';
import { getCounters } from '../claims/actions/claims.action.creators';
import { t } from '../../service/localization/i18n';
import cx from 'classnames';

const Dashboard = () => {
  const dispatch = useDispatch();
  const { DCNGuid } = useSelector((state) => state.user.details);
  const { isMobileOnly } = useSelector((state) => state.core.window);
  const { dashboardActiveTabIndex } = useSelector((state) => state.dashboard);
  const { counters } = useSelector((state) => state.claims);
  const outstandingCounter = counters?.find((i) => i.InvoiceType === 'Teleplan' && i.StatusCode === '0')?.TotalCount;
  const declinedCounter = counters?.find((i) => i.InvoiceType === 'Teleplan' && i.StatusCode === '3')?.TotalCount;

  // get counters
  useEffect(() => {
    // [KS] CMO-1146 - After user creates new outstanding claims, the counters on the Overview do not get updated
    dispatch(getCounters(DCNGuid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const countersTemplate = (options) => {
    return (
      <div
        className={cx('flex align-items-center px-3 font-medium', options.selected ? 'text-primary' : 'text-color-secondary')}
        style={{ cursor: 'pointer' }}
        onClick={options.onClick}
      >
        {t('Counters')}
        {outstandingCounter > 0 && (
          <>
            <Tooltip target=".total-outstanding" position="top">
              Total outstanding
            </Tooltip>
            <Badge value={outstandingCounter} severity="warning" className="total-outstanding ml-2" />
          </>
        )}
        {declinedCounter > 0 && (
          <>
            <Tooltip target=".total-declined" position="top">
              Total declined
            </Tooltip>
            <Badge value={declinedCounter} severity="danger" className="total-declined ml-2" />
          </>
        )}
      </div>
    );
  };

  return (
    <div className="flex col-12 p-0 justify-content-center">
      <div className="px-4 w-full container">
        <TabView activeIndex={dashboardActiveTabIndex} onTabChange={(e) => dispatch(setDashboardActiveTabIndex(e.index))}>
          <TabPanel header={t('Home')} headerClassName="extra-large-text">
            <Home />
          </TabPanel>

          <TabPanel
            header={t('Counters')}
            headerTemplate={countersTemplate}
            headerClassName="flex align-items-center extra-large-text"
            contentClassName={isMobileOnly ? 'p-m--1' : ''}
          >
            <Overview />
          </TabPanel>
        </TabView>
      </div>
    </div>
  );
};

export default Dashboard;
