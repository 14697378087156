import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTable } from '../hooks/useTable';
import CommonConfirmDialog from '../../../../modules/common/components/CommonConfirmDialog';
import { useSimilarClaimsListContext } from '../hooks/useSimilarClaimsListContext';
import { archiveClaimRecord, getClaimList } from '../../../../modules/claims/actions/claims.action.creators';
import { updatedClaimListParams } from '../../../../modules/claims/helpers/rowActions';
import { t } from '../../../../service/localization/i18n';
import moment from 'moment';

const ArchiveDialog = () => {
  const dispatch = useDispatch();
  const moneyTablePatientGuid = useSelector((state) => state.claims.PatientGuid);
  const { state, setState } = useSimilarClaimsListContext();
  const { fetchData } = useTable();
  const { visible, claim } = state.archiveDialog;

  const onAccept = () => {
    dispatch(
      archiveClaimRecord(claim, () => {
        onHide();
        const claimListParams = updatedClaimListParams(moneyTablePatientGuid);
        const serviceDate = moment(state.claim.ServiceDate).format('YYYY/MM/DD');
        dispatch(getClaimList(claimListParams));

        const similarClaimsPatientGuid = state.filters?.patient?.PatientGuid;
        similarClaimsPatientGuid &&
          fetchData({
            patientGuid: similarClaimsPatientGuid,
            query: {
              FeeCode: state.claim.FeeCode,
              Period: '7',
              showArchived: '',
              PeriodFrom: serviceDate,
              PeriodTo: serviceDate,
              ServiceDate: serviceDate
            }
          });
      })
    );
  };

  const onHide = () => setState((prevState) => ({ ...prevState, archiveDialog: { visible: false, claim: null } }));

  return (
    <CommonConfirmDialog
      visible={visible}
      header={t('Archive_Claim')}
      bodyText={String.format(t('Are_you_sure_you_want_to_archive_invoice'), claim?.RecordNo, claim?.FullName)}
      accept={onAccept}
      reject={onHide}
      onHide={onHide}
    />
  );
};

export default ArchiveDialog;
