import React from 'react';
import SimilarClaimsTable from './modules/table/SimilarClaimsTable';
import SimilarClaimsListProvider from './SimilarClaimsListProvider/SimilarClaimsListProvider';
import Dialogs from './Dialogs/Dialogs';

// interface ISimilarClaimsList {
//   patientsList: {
//     PatientGuid: string
//     FirstName: string
//     LastName: string
//     MidName?: string
//     BirthDay: Date
//     label: string // required for patient autocomplete
//   }[]
//   initPatient?: {
//     PatientGuid: string
//     FirstName: string
//     LastName: string
//     MidName?: string
//     BirthDay: Date
//     label: string // required for patient autocomplete
//   }
// }

const SimilarClaimsList = (props) => {
  return (
    <SimilarClaimsListProvider {...props}>
      <SimilarClaimsTable />
      <Dialogs />
    </SimilarClaimsListProvider>
  );
};

export default SimilarClaimsList;
