import React, { useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { SplitButton } from 'primereact/splitbutton';
import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';

import CommonCard from '../common/components/CommonCard';
import ClaimView from '../claims/components/ClaimView';
import Counters from './Counters';
import MSPEligibilityView from './MSPEligibilityView';
import Patients from '../patients/components/Patients/Patients';

import { routes } from '../../routes/routes';
import { setDefaultPatientsTableValues } from '../patients/actions/patients.action.creators';
import { setDefaultClaimsTableValues, getCounters } from '../claims/actions/claims.action.creators';
import { addNewOverlayItemsList } from '../claims/helpers/addNewOverlayItemsList';
import { addNewTeleplan } from '../claims/helpers/addNewInvoice';
import { t } from '../../service/localization/i18n';
import classes from './Overview.module.scss';

const Overview = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { DCNGuid } = useSelector((state) => state.user.details);
  const { isGettingPrefs } = useSelector((state) => state.preferences);
  const { isMobile, isMobileOnly, isLandscape } = useSelector((state) => state.core.window);
  const { isFetchingCounters, isFetchingClaims } = useSelector((state) => state.claims);
  const { isFetchingPHNValidation, isFetchingPatientEligibility, isFetchingPatients } = useSelector((state) => state.patients);
  const addNewInvoiceMenuRef = useRef();

  const patientsViewMore = () => {
    history.push(`${routes.patients.path}`);
    dispatch(setDefaultPatientsTableValues());
  };

  const claimsViewMore = () => {
    history.push(`${routes.invoices.path}`);
    dispatch(setDefaultClaimsTableValues());
  };

  const claimsAndInvoicesHeaderActionButton = (
    <Button
      className="p-button-outlined p-button-rounded"
      icon={isFetchingCounters ? 'pi pi-spin pi-spinner' : 'pi pi-refresh'}
      tooltip={!isMobile && t('Refresh_counters')}
      tooltipOptions={{ position: 'top' }}
      disabled={isFetchingCounters}
      onClick={() => dispatch(getCounters(DCNGuid))}
    />
  );

  const invoicesHeaderActionButton = () => {
    if (isMobileOnly)
      return (
        <Button
          className="p-button-outlined p-button-rounded"
          icon="pi pi-plus"
          tooltip={t('Add_new_invoice')}
          tooltipOptions={{ position: 'top' }}
          disabled={isGettingPrefs}
          aria-controls="add_new_invoice_menu"
          aria-haspopup
          onClick={(e) => addNewInvoiceMenuRef.current.toggle(e)}
        />
      );

    return (
      <SplitButton
        className="p-button-rounded p-button-text"
        label={t('New_claim')}
        disabled={isGettingPrefs}
        model={addNewOverlayItemsList({ history, isSplitButton: true })}
        onClick={() => addNewTeleplan({ history })}
      />
    );
  };

  const patientssHeaderActionButton = (
    <Button
      className="p-button-outlined p-button-rounded"
      icon="pi pi-plus"
      tooltip={t('Add_new_patient')}
      tooltipOptions={{ position: 'top' }}
      onClick={() => history.push(`${routes.createPatient.path}/new`)}
    />
  );

  return (
    <div className={isMobileOnly ? 'py-3' : 'px-3 py-6 flex col-12 justify-content-center'}>
      <Menu id="add_new_invoice_menu" popup ref={addNewInvoiceMenuRef} model={addNewOverlayItemsList({ history })} />

      <div className={classes.cardsWrap}>
        <div className={classes.cardWrap}>
          <CommonCard title={t('Claims_and_Invoices')} headerActionButton={claimsAndInvoicesHeaderActionButton}>
            <Counters isMobileOnly={isMobileOnly} isLandscape={isLandscape} />
          </CommonCard>
        </div>

        <div className={classes.cardWrap}>
          <CommonCard title={t('MSP_Eligibility')}>
            <MSPEligibilityView isFetchingPHNValidation={isFetchingPHNValidation} isFetchingPatientEligibility={isFetchingPatientEligibility} />
          </CommonCard>
        </div>

        <div className={classes.cardWrap}>
          <CommonCard
            title={t('Recent_Billing')}
            showFooter={true}
            isFetching={isFetchingClaims}
            footerButtonLable={t('View_More')}
            footerButtonOnClick={claimsViewMore}
            headerActionButton={invoicesHeaderActionButton()}
          >
            <ClaimView id="claims" showInCard={true} showOnOverview={true} />
          </CommonCard>
        </div>

        <div className={classes.cardWrap}>
          <CommonCard
            title={t('Recent_Patients')}
            showFooter={true}
            isFetching={isFetchingPatients}
            footerButtonLable={t('View_More')}
            footerButtonOnClick={patientsViewMore}
            headerActionButton={patientssHeaderActionButton}
          >
            <Patients showInCard={true} customPageSize={3} />
          </CommonCard>
        </div>
      </div>
    </div>
  );
};

export default Overview;
