import React from 'react';

import { Calendar } from 'primereact/calendar';

import MonthNavigator from './MonthNavigator';
import YearNavigator from './YearNavigator';
import CalendarDateTemplete from './CalendarDateTemplete';

import { defaultYearRange } from '../../../modules/config/defaultValuesConfig';
import cx from 'classnames';

// interface = {
//  value: Date,
//  onChange: (value: Date) => void,

//  ref?: ref,
//  id?: string,
//  name?: string,
//  yearRange?: string,
//  selectionMode?: "single" | "multiple",
//  disabled?: boolean,
//  isInvalid?: boolean,
//  showOnFocus?: boolean,
//  readOnlyInput?: boolean,
//  status?: number | string,
//  maxDate?: Date | ubdefined,
//  onBlur?: (value: Date) => void,
//  onClearButtonClick?: () => void,
// };

const DatePicker = React.forwardRef((props, ref) => {
  const yearRange = props.yearRange || defaultYearRange;

  const handleChange = (value) => {
    props.onChange(value);
  };

  const handleBlur = (value) => {
    if (props.onBlur) props.onBlur(value);
  };

  const monthNavigatorTemplate = (e) => <MonthNavigator event={e} />;
  const yearNavigatorTemplate = (e) => <YearNavigator event={e} />;
  const dateTemplate = (data) => <CalendarDateTemplete data={data} />;

  return (
    <Calendar
      panelClassName={`calendar_cm_status_${props.status}`}
      id={props.id}
      inputRef={ref}
      inputId={`${props.name || 'date'}_input`}
      value={props.value}
      className={cx({ 'p-invalid': props.isInvalid })}
      placeholder="MM/DD/YYYY"
      dateFormat="mm/dd/yy"
      mask={props.selectionMode === 'multiple' ? '' : '99/99/9999'}
      selectionMode={props.selectionMode || 'single'}
      disabled={props.disabled}
      showTime={false}
      maxDate={props.maxDate}
      showIcon
      showButtonBar
      baseZIndex={1000000}
      showOnFocus={props.showOnFocus}
      readOnlyInput={props.readOnlyInput}
      inputMode="text"
      selectOtherMonths
      type="date"
      monthNavigator
      yearNavigator
      yearRange={yearRange}
      monthNavigatorTemplate={monthNavigatorTemplate}
      yearNavigatorTemplate={yearNavigatorTemplate}
      dateTemplate={dateTemplate}
      onChange={(e) => handleChange(e.value)}
      onBlur={(e) => handleBlur(e?.target?.value)}
      onClearButtonClick={() => props.onClearButtonClick?.()}
    />
  );
});

export default DatePicker;
