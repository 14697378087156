import React, { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Tooltip } from 'primereact/tooltip';
import { Button } from 'primereact/button';
import { AutoComplete } from 'primereact/autocomplete';

import HighlightSearchQuery from '../../components/Misc/HighlightSearchQuery/HighlightSearchQuery';

import { getPatients, getClaims } from '../../service/Lookup';
import { formatPatientInfoForClaims } from '../patients/helpers/formatPatientInfoForClaims';
import { viewPatientProfile } from '../patients/components/Patients/helpers/rowActionsMenuItems';
import { patientFullNameWithAge } from '../utils/patientFullName';
import { noResultsError } from '../common/components/inputInfoMessages';
import { onClaimViewClick } from '../claims/helpers/rowActions';
import { patientPickerPageSize } from '../config/defaultValuesConfig';
import { setSearchBy } from './actions/dashboard.action.creator';
import { formatted, updateClaimList, updateClaimsState } from '../claims/actions/claims.action.creators';
import { t } from '../../service/localization/i18n';
import classes from './GlobalSearchInput.module.scss';
import moment from 'moment';
import cx from 'classnames';
import { Dialog } from 'primereact/dialog';

const HowToFindLayout = () => {
  return (
    <>
      <div className="underline font-bold">How to find a patient:</div>

      <ol className="flex flex-column" style={{ rowGap: '8px' }}>
        <li>Make sure "Find Patient" button is selected (blue).</li>
        <li>Search by Last Name, First Name. E.g. Smith, Anne. Make sure to add a comma after the last name.</li>
        <li>Search by the last 7 digits of phone number. E.g. 1234567 for 778-123-4567. Note that area code and separators are omitted.</li>
        <li>Search by birthdate: YYYY/MM/DD or MM/DD/YYYY. E.g. 1980/12/31 or 12/31/1980.</li>
      </ol>

      <div className="underline font-bold pt-4">How to find a claim:</div>

      <ol className="flex flex-column" style={{ rowGap: '8px' }}>
        <li>Make sure 'Find Claim" button is selected (blue)</li>
        <li>Search by claim number. Pay attention to claim prefix:</li>

        <ul className="flex flex-column" style={{ rowGap: '8px', listStyleType: 'disc' }}>
          <li>seq. # means Teleplan sequential number.</li>
          <li>P - office # for private invoice,</li>
          <li>M - office number for MSP claim,</li>
          <li>I - office number for ICBC claim,</li>
          <li>W - office number for WorkSafeBC claim,</li>
          <li>R - office number for WSBC report.</li>
          <li>Office number means claim's number in Claim Manager system.</li>
        </ul>
      </ol>
    </>
  );
};

const GlobalSearchInput = ({ name, placeholder }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const { searchBy } = useSelector((state) => state.dashboard);
  const { isMobile, isMobileOnly, isLandscape } = useSelector((state) => state.core.window);

  const [value, setValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [showDialog, setShowDialog] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [query, setQuery] = useState('');
  const [isSuggestionsFound, setIsSuggestionsFound] = useState(true);
  const globalSearchRef = useRef(null);
  const mobileView = isMobileOnly && !isLandscape;
  const switchOn = mobileView ? name : searchBy;

  const fetchPatients = (searchQuery) => {
    setQuery(searchQuery);
    setIsFetching(true);

    switch (switchOn) {
      // get patients
      case 'patient':
        getPatients(searchQuery).then((patients) => {
          setIsFetching(false);
          const patientsList = patients.patientList.map((x) => formatPatientInfoForClaims(x));
          patientsList?.length > 0 ? setIsSuggestionsFound(true) : setIsSuggestionsFound(false);
          setSuggestions(patientsList);
        });
        break;

      // get claims
      case 'claim':
        getClaims(user.details.DCNGuid, searchQuery, dispatch, patientPickerPageSize).then((claims) => {
          setIsFetching(false);
          claims?.length > 0 ? setIsSuggestionsFound(true) : setIsSuggestionsFound(false);
          setSuggestions(claims);
        });
        break;

      default:
        break;
    }
  };

  const onChange = (e) => {
    setValue(e.target.value);
    e.target.value.length === 0 && !isSuggestionsFound && setIsSuggestionsFound(true);
  };

  const onSelect = (e) => {
    switch (switchOn) {
      // on select patient
      case 'patient':
        viewPatientProfile(e.value, history);
        break;

      // on select claim
      case 'claim':
        const formattedValue = formatted([e.value])[0];
        dispatch(updateClaimList([formattedValue])); // Update state to correctly display pagination of claims ViewPanel
        dispatch(
          updateClaimsState({
            page: 1,
            pageSize: 1,
            totalPages: 1,
            totalRecords: 1,
            totalRecordsAlter: 1
          })
        ); // Update state to correctly display pagination of claims ViewPanel
        onClaimViewClick(formattedValue, history);
        break;

      default:
        break;
    }
  };

  const clearInput = () => {
    setValue('');
    globalSearchRef?.current?.inputRef?.current?.focus();
    setIsSuggestionsFound(true);
  };

  const formatItemTemplate = (data) => {
    if (switchOn === 'patient') {
      return (
        <>
          <div>
            <HighlightSearchQuery query={query} text={patientFullNameWithAge(data)} />
          </div>
          <div className="flex">
            <span className="pr-2">{`${t('Day_of_birthday.1')}:`}</span>
            <HighlightSearchQuery query={query} text={data.DOB} />
          </div>
          <div className="flex">
            <span className="pr-2">{`${t('PHN')}:`}</span>
            <HighlightSearchQuery query={query} text={data.PHN} />
          </div>
        </>
      );
    }

    if (switchOn === 'claim') {
      const recordNum = data.SequenceNum ? `${data.RecordNo} (${t('Sequence.1').toLowerCase()}.# ${data.SequenceNum})` : data.RecordNo;

      return (
        <>
          <div className="flex">
            <span className="pr-2">
              <HighlightSearchQuery query={query} text={`${recordNum},`} />
            </span>
            <HighlightSearchQuery query={query} text={patientFullNameWithAge(data)} />
          </div>

          <div className="flex">
            <span className="pr-2">{`${t('Day_of_birthday.1')}:`}</span>
            <HighlightSearchQuery query={query} text={moment(data?.BirthDay).format('MM/DD/YYYY')} />
          </div>
        </>
      );
    }
  };

  const findPatient = () => {
    dispatch(setSearchBy('patient'));
    value && setValue('');
  };

  const findClaim = () => {
    dispatch(setSearchBy('claim'));
    value && setValue('');
  };

  const currentPlaceholder = () => {
    if (placeholder) return placeholder;
    if (!placeholder) {
      if (switchOn === 'patient') return t('Enter_name_PHN_phone_or_other_patient_information_here');
      if (switchOn === 'claim') return t('Enter_office_number_or_sequence_number_of_the_claim_here');
    }
  };

  const onQuestionIconClick = () => {
    setShowDialog(!showDialog);
  };

  const renderFooter = () => {
    return <Button label={t('Close')} onClick={onQuestionIconClick} autoFocus />;
  };

  return (
    <>
      <div className={mobileView ? classes.inputRootMobile : classes.inputRootDesktop}>
        <i className={cx('pi pi-search ml-2', classes.searchIcon)} />

        <AutoComplete
          id="globalPatientsSearch"
          className={cx('w-full', mobileView ? '' : classes.searchbarDesktop)}
          inputClassName={mobileView ? classes.searchbarInputMobile : classes.searchbarInputDesktop}
          ref={globalSearchRef}
          value={value}
          autoFocus={isMobile ? false : true}
          suggestions={suggestions}
          placeholder={currentPlaceholder()}
          autoHighlight
          field={switchOn === 'patient' ? 'label' : 'RecordNo'}
          dropdownMode="current"
          scrollHeight={500}
          onChange={onChange}
          onSelect={onSelect}
          completeMethod={(e) => fetchPatients(e.query)}
          itemTemplate={formatItemTemplate}
        />

        {mobileView ? null : (
          <div className={classes.searchInputOptionsButtons}>
            <Button
              className={cx('py-2 mx-1', searchBy === 'patient' ? 'p-button-rounded' : cx('p-button-link', classes.searchOptionButtonInactive))}
              label={t('Find_patient')}
              onClick={findPatient}
            />
            <Button
              className={cx('py-2 mx-1', searchBy === 'claim' ? 'p-button-rounded' : cx('p-button-link', classes.searchOptionButtonInactive))}
              label={t('Find_claim')}
              onClick={findClaim}
            />
          </div>
        )}

        <>
          {!isMobileOnly && (
            <Tooltip target=".questionIconTooltip" position="top">
              <HowToFindLayout />
            </Tooltip>
          )}

          <div className={cx('questionIconTooltip', mobileView ? classes.questionIconWrapMobile : classes.questionIconWrapDesktop)}>
            <i
              className={cx('pi pi-question-circle', mobileView ? classes.questionIconMobile : classes.questionIconDesktop)}
              onClick={isMobileOnly ? onQuestionIconClick : undefined}
            />
          </div>
        </>

        {value?.length > 0 && (
          <>
            <Tooltip target=".clearIconTooltip" position="top" />
            <div
              className={cx('clearIconTooltip', mobileView ? classes.clearIconWrapMobile : classes.clearIconWrapDesktop)}
              data-pr-tooltip={t('Clear')}
              onClick={clearInput}
            >
              {!isFetching && <i className={cx('pi pi-times mr-2', mobileView ? classes.clearIconMobile : classes.clearIconDesktop)} />}
            </div>
          </>
        )}

        {mobileView ? null : <span className={classes.clearIconSplitter}></span>}
      </div>

      {!isSuggestionsFound && <div className="my-1 ml-4">{noResultsError}</div>}

      <Dialog visible={showDialog} style={{ width: '95vw' }} footer={renderFooter()} onHide={onQuestionIconClick}>
        <HowToFindLayout />
      </Dialog>
    </>
  );
};

export default GlobalSearchInput;
