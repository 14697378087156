import React from 'react';
import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import { Skeleton } from 'primereact/skeleton';

import { addNewItemToArray } from '../../utils/addNewItemToArray';
import { icons } from '../../config/stylesConfig';
import { confirmDialogNames } from './confirmDialogNames';
import { claimsDefaultSortBy, defaultGuid } from '../../config/defaultValuesConfig';

import {
  setClaimInfo,
  setDefaultClaimsTableValues,
  setFilterByUserForInvoicesViewPanel,
  saveClaimRecord,
  archiveClaimRecord,
  getClaimList,
  getClaimInfo,
  updatePrivateRecord,
  setPaymentDetails,
  saveWSBCReport,
  sendByEmail,
  createPrivateInvoice,
  duplicateEClaim,
  archiveEClaim
} from '../actions/claims.action.creators';
import { store } from '../../../store';
import { routes } from '../../../routes/routes';
import { onPrint, setLoading, setToastMessage } from '../../core/actions/core.action.creators';
import { submitNewClaimSuccessMessage } from './toastMessages';
import { privateBlankRecord } from '../components/PrivateDetails/claimDetailsHelpers/newClaimBlankRecord';
import { printTypes } from '../../../components/Layouts/Print/helpers/printTypes';
import { getPatientInfo, setPatientInfo } from '../../patients/actions/patients.action.creators';
import { getEOBLink } from '../../utils/getLink';
import { v4 as uuidv4 } from 'uuid';
import { t } from '../../../service/localization/i18n';
import classes from '../../../styles/cm_col.module.scss';
import moment from 'moment';
import { getEClaimFormName } from '../../utils/getEClaimFormName';

export const rowActions = (actionButtonsParams) => {
  return [
    {
      field: '',
      header: '',
      isTablet: actionButtonsParams.isTablet,
      className: actionButtonsParams.isTablet
        ? classes.cm_col_tablet
        : !actionButtonsParams.isMobile && actionButtonsParams.clientWidth > 1600
        ? classes.cm_col_8em
        : classes.cm_col_5em,
      resetSort: (onSort) => {
        return (
          <Button
            className="p-button-text"
            icon="pi pi-sort-amount-down"
            style={actionButtonsParams.isTablet ? { width: '2.4em', height: '2.4em' } : {}}
            tooltip={t('Default_sort_order.1')}
            tooltipOptions={{ position: 'top' }}
            onClick={() => onSort(claimsDefaultSortBy.sortField, -1)}
          />
        );
      },
      // body2: (e) => (
      //   <Button
      //     icon='fas fa-history'
      //     onClick={() => actionButtonsParams.history.push(`${routes.editClaimRecord.path}/${e.InvoiceGuid}`)}
      //   />
      // ),
      body: (data) => {
        const ref = React.createRef();
        const params = { ...actionButtonsParams, data };

        return (
          <>
            <div className="tabletDataTableActionMenu flex justify-content-center">
              {params.isFetching ? (
                <div>
                  <Skeleton shape="circle" size="0.3rem" className="mb-1" />
                  <Skeleton shape="circle" size="0.3rem" className="mb-1" />
                  <Skeleton shape="circle" size="0.3rem" className="mb-1" />
                </div>
              ) : (
                <>
                  <Button
                    className="p-button-text p-button-rounded mobileRoundedButtonSize"
                    icon="pi pi-ellipsis-v"
                    aria-controls={`popup_menu_${data.InvoiceGuid}`}
                    aria-haspopup
                    onClick={(e) => ref.current.toggle(e)}
                  />
                  <Menu id={`popup_menu_${data.InvoiceGuid}`} model={rowActionsMenuItems(params)} popup ref={ref} />
                </>
              )}
            </div>
          </>
        );
      }
    }
  ];
};

export const patientButton = ({ patientGuid, history, replaceHistory }) => {
  return {
    id: 'patients_profile',
    icon: icons.patient,
    label: t('Patient.1'),
    command: () => openPatientProfile(patientGuid, history, replaceHistory)
  };
};

export const commonEClaimsActions = ({ eClaim, history, replaceHistory = false, setConfirmDialog } = {}) => {
  let commonItems = [
    {
      id: 'duplicateEClaim',
      icon: 'pi pi-clone',
      label: t('Duplicate'),
      command: () => onDuplicateEClaim({ eClaim, history, replaceHistory })
    }
  ];

  const eob = {
    id: 'eob',
    icon: 'pi pi-shield',
    label: t('Show_EOB'),
    command: () => onEOB({ eClaim })
  };

  const invoicePatientForBalance = {
    id: 'invoicePatientForBalance',
    icon: 'pi pi-dollar',
    label: t('Invoice_patient_for_the_balance'),
    command: () => handleInvoicePatientForBalance({ eClaim, history })
  };

  const archiveEClaim = {
    id: 'archiveEClaim',
    icon: icons.archived,
    label: t('Archive'),
    command: () => setConfirmDialog(eClaim, confirmDialogNames.archiveEClaim)
  };

  const feePaid = eClaim?.FeePaid;
  const feeTotal = eClaim?.FeeTotal;
  const isRecived = eClaim?.Received;
  const remittanceCode = eClaim?.RemittanceCode;
  const remittanceCodeValue = getEClaimFormName(remittanceCode);
  const status = Number(eClaim?.Status);
  const isOutstanding = status === 0;
  const isError = remittanceCodeValue === 'error';

  if (isRecived && !isOutstanding) commonItems = addNewItemToArray(commonItems, 0, eob);
  if (isError) commonItems = addNewItemToArray(commonItems, 3, archiveEClaim);

  // VER-345 - eClaims->View claim/Rollodex and Money page->3-dot->add 'Invoice patient for the balance"
  if (feePaid < feeTotal) commonItems = addNewItemToArray(commonItems, 1, invoicePatientForBalance);

  return commonItems;
};

export const rowActionsMenuItems = (params) => {
  let commonItems = [];
  const status = Number(params.data?.Status);
  const isOutstanding = status === 0;
  const isDeclined = status === 3;
  const type = params?.data?.ClaimType;
  const isEClaim = type === 'E';
  const isTeleplan = type === 'M' || type === 'W' || type === 'R' || type === 'I';
  const isPrivate = type === 'P';
  const isMoneyPage = params.history?.location?.pathname.includes('money/invoices');
  const isPatientProfilePage = params.history?.location?.pathname.includes('patient-details');
  const isClaimProfilePage = params.history?.location?.pathname.includes('claim_profile');

  const view = {
    id: 'info',
    icon: icons.info,
    label: t('Carousel'),
    command: () => onClaimViewClick(params.data, params.history)
  };

  const claimProfile = {
    id: 'claim_profile',
    icon: icons.info,
    label: t('Claim_profile'),
    command: () => onClaimProfileClick(params.data, params.history)
  };

  const deleteClaim = {
    id: 'delete',
    icon: icons.delete,
    label: t('Delete'),
    command: () => params.setConfirmDialog(params.data, confirmDialogNames.delete)
  };

  const debit = {
    id: 'debit',
    icon: icons.debit,
    label: t('Debit'),
    command: () => onDebitClick(params.data, params.setConfirmDialog)
  };

  const edit = {
    id: 'edit',
    icon: icons.edit,
    label: editLabel(status, type),
    command: () => onEditInvoiceClick(params)
  };

  const print = {
    id: 'print',
    icon: icons.print,
    label: t('Print'),
    command: () => handlePrint(params)
  };

  const submitOutstandingInvoice = {
    id: 'submitOutstandingInvoice',
    icon: icons.submitOutstandingInvoice,
    label: t('Submit'),
    command: () => handleSubmit(params)
  };

  const patient = patientButton({ patientGuid: params.data?.PatientGuid, history: params.history, replaceHistory: params.showOnViewPanel });

  const archived = {
    id: 'archived',
    icon: icons.archived,
    label: t('Archive'),
    command: () => params.setConfirmDialog(params.data, confirmDialogNames.archive)
  };

  const invoice = {
    id: 'invoice',
    icon: icons.invoice,
    label: t('Invoices.3'),
    command: () => onInvoiceClick(params.data, params.history, params.showOnViewPanel)
  };

  const wsbcrPreview = {
    id: 'wsbcrPreview',
    icon: icons.invoice,
    label: `${t('Form')} 8/11`,
    command: () => openWSBCRPreview(params.data?.InvoiceGuid, params.history)
  };

  const receivePayment = {
    id: 'receivePayment',
    icon: icons.money,
    label: t('Receive_Payment'),
    command: () => onReceivePayment(params)
  };

  const viewPayments = {
    id: 'viewPayments',
    icon: 'pi pi-list',
    label: t('View_payments'),
    command: () => params.setConfirmDialog(params.data, confirmDialogNames.viewPayments)
  };

  const viewEClaim = {
    id: 'viewEClaim',
    icon: icons.invoice,
    label: t('View_claim'),
    command: () => onViewEClaim({ invoiceGuid: params.data?.InvoiceGuid, history: params.history })
  };

  const sendViaEmail = {
    id: 'sendViaEmail',
    icon: 'pi pi-envelope',
    label: t('Send_by_email'),
    command: () => onSendViaEmail(params.data?.ClaimNumber, params.data?.PatientGuid)
  };

  const addClaimNote = {
    id: 'addClaimNote',
    icon: 'pi pi-file-edit',
    label: 'Add claim note',
    command: () => params.setConfirmDialog(params.data, confirmDialogNames.addClaimNote)
  };

  const editPatientsProfile = {
    id: 'editPatientsProfile',
    icon: 'pi pi-user-edit',
    label: 'Edit profile',
    command: () => editPatientProfile(params)
  };

  const billingHistory = {
    id: 'billingHistory',
    icon: 'pi pi-history',
    label: 'Billing history',
    command: () => {
      store.dispatch(setClaimInfo(params.data)); // highlight line in the data table
      params.setConfirmDialog(params.data, confirmDialogNames.billingHistory);
    }
  };

  const similarClaims = {
    id: 'similarClaims',
    icon: 'pi pi-clone',
    label: 'Show similar claims',
    command: () => {
      store.dispatch(setClaimInfo(params.data)); // highlight line in the data table
      params.setConfirmDialog(params.data, confirmDialogNames.similarClaims);
    }
  };

  if (!params.readonly) {
    //show View button
    if (params.data?.RecType && !params.hideViewButton) {
      commonItems = addNewItemToArray(commonItems, 1, view);
    }

    if (type !== 'P' && type !== 'E' && !isClaimProfilePage) {
      commonItems = addNewItemToArray(commonItems, 1, claimProfile);
      commonItems = addNewItemToArray(commonItems, 2, addClaimNote);
    }

    //show Edit button
    if ((status === 1 || status === 2 || status === 21) && type !== 'P' && type !== 'W' && type !== 'E') {
      commonItems = addNewItemToArray(commonItems, 1, debit);
    } else {
      // [KS] CMO-1081 - Hide Edit button on Archive and Private records in 1st release because it does not work
      if (
        params.data?.ClaimType === 'P' &&
        !params.data?.Locked && // CMO-2248 - Private invoice : make non-editable invoices and/or service lines created in old UI
        params.data?.FeeCode !== 'eClaims Balance' && // CMO-2210 - Invoice->eClaims balance as a service->disable all Edit features for such invoices
        ((status !== 201 && status !== 2) || params.data?.ClaimType === 'P')
      ) {
        commonItems = addNewItemToArray(commonItems, 1, edit);
      }
    }

    //show Edit button
    const isDraft = status === 4 && type !== 'P'; //show "Edit" button for draft
    const isPaidRebilledOrAdjusted = (status === 2 || status === 21 || status === 200) && type !== 'P' && type !== 'R' && type !== 'W'; //show "Edit" button for Paid, Rebilled and Adjusted
    const isDeclinedOrNotPaid = status === 3 || status === 6; //show Edit button for Declined and Not Paid
    const isInProcessTeleplan = status === 1 && type !== 'W'; // CMO-2773 - Add rebill button to in process teleplan claims

    if (!isEClaim && (isDraft || isPaidRebilledOrAdjusted || isDeclinedOrNotPaid || isInProcessTeleplan)) {
      commonItems = addNewItemToArray(commonItems, 1, edit);
    }

    //show Archived button for Declined and Not Paid
    if (isDeclinedOrNotPaid) {
      commonItems = addNewItemToArray(commonItems, commonItems.length, archived);
    }

    //show "Submit" button
    if (type === 'E') {
      const eClaimsActions = commonEClaimsActions({ ...params, eClaim: params?.data, setConfirmDialog: params.setConfirmDialog });
      commonItems = addNewItemToArray(commonItems, 1, eClaimsActions);
      if (!isOutstanding) commonItems = addNewItemToArray(commonItems, 1, viewEClaim);
    }

    //show "Submit" button
    if (status === 0 && type !== 'P') {
      commonItems = addNewItemToArray(commonItems, 1, edit);
      commonItems = addNewItemToArray(commonItems, 2, submitOutstandingInvoice);
    }

    //show Invoice button
    if (type === 'P') {
      commonItems = addNewItemToArray(commonItems, commonItems?.length, receivePayment);
      if (!params.hideInvoiceButton) {
        commonItems = addNewItemToArray(commonItems, 1, invoice);
      }
    }

    //show Form 8/11 button
    if (type === 'R') {
      commonItems = addNewItemToArray(commonItems, 1, wsbcrPreview);
    }
  }

  //show "View payments" button
  if (type === 'P') {
    commonItems = addNewItemToArray(commonItems, 4, viewPayments);
  }

  //show Patient button
  if (!params.showOnPatientDetailsScreen) {
    commonItems = addNewItemToArray(commonItems, 5, patient);
  }

  //show Edit patient profile button
  if (isMoneyPage) {
    commonItems = addNewItemToArray(commonItems, 6, editPatientsProfile);
  }

  //show Billing history button
  if (!isClaimProfilePage && !isPatientProfilePage) {
    commonItems = addNewItemToArray(commonItems, 7, billingHistory);
  }

  //show Similar claims button
  if (isTeleplan) {
    commonItems = addNewItemToArray(commonItems, 7, similarClaims);
  }

  //show "Send by email" button
  if (type === 'P' && (status === 0 || status === 2)) {
    commonItems = addNewItemToArray(commonItems, commonItems?.length, sendViaEmail);
  }

  //show Print button
  if (type === 'P' || type === 'R') {
    commonItems = addNewItemToArray(commonItems, commonItems?.length, print);
  }

  //show Delete button
  const showDeleteForEClaim = isEClaim && (status === 0 || status === 4); // VER-5 - [eClaims] remove Rebill and Debit from 3 dot button - Remove Delete from eClaims that are not outstanding or drafts (if we have them). Only they can be deleted.
  const showDelete = !isEClaim && !isPrivate && (status === 0 || status === 4);
  const showDeleteForPrivate = isPrivate && (status === 0 || status === 4 || status === 2); // CMO-1960 - Add the ability to delete paid invoice

  if (showDeleteForEClaim || showDelete || showDeleteForPrivate) {
    commonItems = addNewItemToArray(commonItems, commonItems.length, deleteClaim);
  }

  return commonItems;
};

const editLabel = (status, type) => {
  // Rename "Edit" to "Rebill" for Declined, Not Paid, Paid and Adjusted statuses
  if ((status === 1 || status === 3 || status === 6 || status === 2 || status === 21 || status === 200) && type !== 'P') return t('Rebill');
  return t('Edit');
};

const onDebitClick = (data, setConfirmDialog) => {
  setConfirmDialog(data, confirmDialogNames.debit);
};

export const onClaimViewClick = (data, history, replaceHistory = false) => {
  store.dispatch(setFilterByUserForInvoicesViewPanel(true));
  store.dispatch(setClaimInfo(data)); // set claim info to highlight it in the invoices data table
  const navTo = `${routes.claimInfo.path}`;
  replaceHistory ? history.replace(navTo) : history.push(navTo);
};

const onClaimProfileClick = (data, history, replaceHistory = false) => {
  const navTo = `${routes.claimProfile.path}/${data.InvoiceGuid}`;
  replaceHistory ? history.replace(navTo) : history.push(navTo);
};

export const onInvoiceClick = (data, history, replaceHistory = false) => {
  store.dispatch(setFilterByUserForInvoicesViewPanel(true));
  store.dispatch(setClaimInfo(data)); // set claim info to highlight it in the invoices data table
  const navTo = `${routes.privetInvoice.path}/${data?.InvoiceGuid}`;
  replaceHistory ? history.replace(navTo) : history.push(navTo);
};

export const openPatientProfile = (patientGuid, history, replaceHistory = false) => {
  store.dispatch(setDefaultClaimsTableValues()); // [KS] CMO-810 - Discard filter on Invoices table after we click on View Patient on any claim's line because the filter gets applied to Invoices card on Patient's profile

  const navTo = `${routes.patientDetails.path}/${patientGuid}`;
  replaceHistory ? history.replace(navTo) : history.push(navTo);
};

export const onArchiveClick = (data, dispatch, patientGuid, showOnViewPanel = false) => {
  dispatch(
    archiveClaimRecord(data, () => {
      const claimListParams = updatedClaimListParams(patientGuid, showOnViewPanel);
      dispatch(getClaimList(claimListParams));
    })
  );
};

export const onEditInvoiceClick = (params) => {
  let path = '';
  if (params.path) {
    path = params.path;
  } else {
    if (params?.data?.ClaimType === 'P') {
      path = routes.privateRecord.path;
    } else if (params?.data?.ClaimType === 'R') {
      path = routes.wsbcReport.path;
    } else if (params?.data?.ClaimType === 'E') {
      path = routes.eClaimEdit.path;
    } else {
      path = routes.editClaimRecord.path;
    }
  }

  store.dispatch(setClaimInfo(params.data)); // highlight line in the data table

  if (params?.data?.ClaimType === 'P') {
    // get nested data object for Private invoice
    store.dispatch(
      getClaimInfo(
        params.data?.InvoiceGuid,
        (responseData) => {
          store.dispatch(updatePrivateRecord(privateBlankRecord({ privateRecord: responseData })));
          onEditCommonActions(params?.data, path, params.history, params.showOnViewPanel);
        },
        'P'
      )
    );
  } else {
    onEditCommonActions(params?.data, path, params.history, params.showOnViewPanel);
  }
};

export const onEditCommonActions = (data, path, history, replaceHistory = false) => {
  //  dispatch(setClaimInfo(data)); !!!DO NOT USE setClaimInfo TO HIGHLIGHT INVOICE because of CMO-1574 - ViewPanel - undefined is displaying!!! // set claim info to highlight it in the invoices data table
  const navTo = `${path}/${data?.InvoiceGuid}`;
  replaceHistory ? history.replace(navTo) : history.push(navTo);
};

export const onReceivePayment = (params) => {
  store.dispatch(setClaimInfo(params.data)); // highlight line in the data table
  store.dispatch(setPaymentDetails(params.data));
  const navTo = `${routes.receivePayment.path}/${params.data?.InvoiceGuid}`;
  params.replaceHistory || params.showOnViewPanel ? params.history.replace(navTo) : params.history.push(navTo);
};

const handleSubmit = (params) => {
  // Submit WSBC Report
  // if (params?.data?.ClaimType === "R") {
  //   const wsbcrRequestData = { ...params?.data, [wsbcrInputs.sendNow.name]: true }
  //   return onSubmitOutstandingWSBCR({ report: wsbcrRequestData, setConfirmDialog: params.setConfirmDialog });
  // }

  // Common submit
  return onSubmitOutstandingInvoice(params);
};

export const onSubmitOutstandingWSBCR = ({ report, setConfirmDialog }) => {
  const _callback = (response) => {
    if (response.error) {
      setConfirmDialog(response);
      store.dispatch(setClaimInfo(response.data)); // set claim info to highlight it in the invoices data table
    } else {
      const claimListParams = updatedClaimListParams(response.data.PatientGuid);
      store.dispatch(setToastMessage({ type: 'success', message: submitNewClaimSuccessMessage(response.data) }));
      store.dispatch(getClaimList(claimListParams));
    }
  };

  store.dispatch(saveWSBCReport(report, _callback));
};

export const onSubmitOutstandingInvoice = (params) => {
  const patientList = params?.data?.PatientList ? params?.data?.PatientList : params?.data?.PatientGuid;
  const updatedData = {
    ...params.data,
    PatientList: patientList,
    SendNow: true,
    InvoiceType: formatInvoiceType(params.data.InvoiceType)
  };

  const _callback = (response) => {
    if (response.error) {
      params.setConfirmDialog(response, confirmDialogNames.updatePatientProfile);
      store.dispatch(setClaimInfo(params.data)); // set claim info to highlight it in the invoices data table
    } else {
      const claimListParams = {
        ...updatedClaimListParams(params.patientGuid, params.showOnViewPanel),
        invoiceGuid: updatedData.InvoiceGuid,
        action: 'submit'
      };
      store.dispatch(setToastMessage({ type: 'success', message: submitNewClaimSuccessMessage(response.data) }));
      store.dispatch(getClaimList(claimListParams));
    }
  };

  store.dispatch(saveClaimRecord(updatedData, _callback));
};

export const updatedClaimListParams = (patientGuid, showOnViewPanel = false) => {
  const claims = store.getState().claims;
  const pageSize = claims.pageSize;
  const filters = claims.filters;
  const sortby = claims.sortby;
  const listOfClaims = claims.claim_list;
  const page = claims?.page === claims?.totalPages && listOfClaims?.length === 1 ? claims.page - 1 : claims.page;
  const params = {
    page: page === 0 ? 1 : page,
    pageSize: pageSize,
    filter: JSON.stringify(filters),
    sortby
  };

  const updateClaimListParams = {
    ...params,
    query: `pid=${patientGuid}&sortby=${sortby}&q=${JSON.stringify(filters)}`,
    showOnDetailsView: showOnViewPanel
  };

  delete updateClaimListParams.sortby;

  return updateClaimListParams;
};

const formatInvoiceType = (invoiceType) => {
  return invoiceType === 'MSP'
    ? 'M'
    : invoiceType === 'WSBC'
    ? 'W'
    : invoiceType === 'WSBCR'
    ? 'R'
    : invoiceType === 'ICBC'
    ? 'I'
    : invoiceType === 'Private'
    ? 'P'
    : invoiceType === 'Extended'
    ? 'E'
    : '';
};

export const onSendViaEmail = (claimNumber, patientGuid) => {
  store.dispatch(
    getPatientInfo({
      id: patientGuid,
      showLoader: false,
      callback: (patient) => {
        if (patient?.Email) {
          const patientName = `${patient?.LastName}, ${patient?.FirstName}`;
          const successMessage = String.format(t('The_invoice_for_patient_will_be_send_to_email'), patientName, patient?.Email);
          store.dispatch(setToastMessage({ type: 'success', message: successMessage }));
        }
      }
    })
  );
  store.dispatch(sendByEmail(claimNumber));
};

const handlePrint = (params) => {
  let printType = '';
  if (params?.data?.ClaimType === 'P') printType = printTypes().privateInvoice.type;
  if (params?.data?.ClaimType === 'R') printType = printTypes().wsbcReport.type;

  store.dispatch(onPrint({ recordId: params.data.InvoiceGuid, printType }));
};

export const openWSBCRPreview = (invoiceGuid, history, replace = false) => {
  const path = `${routes.wsbcReportPreview.path}/${invoiceGuid}`;
  replace ? history.replace(path) : history.push(path);
};

export const onViewEClaim = ({ invoiceGuid, history, replace = false }) => {
  const navTo = `${routes.eClaimView.path}/${invoiceGuid}`;
  replace ? history.replace(navTo) : history.push(navTo);
};

export const onEOB = ({ eClaim }) => {
  const eobLink = getEOBLink(eClaim);
  window.open(eobLink, '_blank');
};

export const onDuplicateEClaim = async ({ eClaim, history, replaceHistory = false }) => {
  store.dispatch(setLoading(true));
  const result = await duplicateEClaim(eClaim);
  store.dispatch(setLoading(false));

  if (result) {
    const navTo = `${routes.eClaimEdit.path}/${result?.ClaimGuid}`;
    replaceHistory ? history.replace(navTo) : history.push(navTo);
  }
};

export const onArchiveEClaim = async ({ eClaim, patientGuid, showOnViewPanel = false }) => {
  const result = await archiveEClaim(eClaim);

  if (result) {
    const claimListParams = updatedClaimListParams(patientGuid, showOnViewPanel);
    store.dispatch(getClaimList(claimListParams));
  }
};

export const handleInvoicePatientForBalance = async ({ eClaim, history }) => {
  const serviceDate = Array.isArray(eClaim?.ServiceDate) ? eClaim?.ServiceDate?.[0] : eClaim?.ServiceDate;
  const formattedServiceDate = moment(serviceDate).format('YYYY-MM-DD');
  const owes = eClaim?.FeeTotal - eClaim?.FeePaid;
  const serviceList = [{ amount: owes, label: 'eClaims Balance', value: uuidv4() }];

  const payload = {
    BillToGuid: eClaim.PatientGuid,
    ClaimGuid: defaultGuid,
    DCNGuid: eClaim.DCNGuid,
    DoctorGuid: eClaim.DoctorGuid,
    FeeTotal: owes,
    InvoiceDate: formattedServiceDate,
    InvoiceGuid: defaultGuid,
    InvoiceType: 'P',
    PatientList: [eClaim.PatientGuid],
    PayToGuid: eClaim.DoctorGuid,
    ServiceDate: formattedServiceDate,
    ServicesList: serviceList,
    Speciality: eClaim.Speciality,
    Status: 0
  };

  const result = await createPrivateInvoice(payload);
  if (result) onEditInvoiceClick({ data: result, history, showOnViewPanel: true });
};

const editPatientProfile = (params) => {
  store.dispatch(setLoading(true));
  store.dispatch(
    getPatientInfo({
      id: params.data?.PatientGuid,
      callback: (patient, error) => {
        store.dispatch(setLoading(false));

        if (!error) {
          store.dispatch(setPatientInfo(patient));
          params.setConfirmDialog(params.data, confirmDialogNames.editPatient);
          store.dispatch(setClaimInfo(params.data)); // set claim info to highlight it in the invoices data table
        }
      }
    })
  );
};
