import { getRedirectUrl } from '../../config';
import { routes } from '../../routes/routes';
import { t } from '../../service/localization/i18n';
import { getSpeciality } from './specialitiesConfig';
import { icons } from './stylesConfig';
import { sendLogout } from '../../service/ClinicService';
import { toTitleCase } from '../../modules/utils/toTitleCase';
import { getTotalOutstanding } from '../utils/getTotalOutstanding';
import { store } from '../../store';
import { setShowTotalOutstandingDialog } from '../claims/actions/claims.action.creators';
import { onSwitchToOldDesign } from '../auth/actions/auth.actions.creators';

const hostname = document.location.hostname;

export const mainMenuLabels = {
  dashboard: t('Home'),
  overview: t('Overview'),
  appointments: t('Appointments.1'),
  schedule: t('Schedule'),
  patients: t('Patient.3'),
  money: t('Money'),
  memos: t('Memos'),
  mailbox: t('Mailbox'),
  invoices: t('Invoices.1'),
  payments: t('Payments.1'),
  roles: t('Roles'),
  staff: t('Staff'),
  reports: t('Reports'),
  settings: t('Settings'),
  billing: t('Billing'),
  clinic: t('Clinic'),
  legacy: t('Switch back'),
  mail: t('Mail'),
  claimInbox: t('ClaimInbox'),
  insurers: t('Insurers'),
  teleplan: t('Teleplan'),
  support: t('Support'),
  audit: t('Audit')
};

//AppMenu (main nav left sidebar)
const parentIconStyle = { fontSize: '23px', display: 'flex', alignItems: 'center' };
const childIconStyle = { fontSize: '14px', display: 'flex', justifyContent: 'end', alignItems: 'center' };
const showRoles = window.location.hostname === 'localhost' || window.location.hostname.includes('nopaperwork');

const mainDefaultMenu = [
  {
    // label: 'Test', icon: 'pi pi-fw pi-star-o', badge: 6,  Label block hiden using App.css -> Ctrl+F for //AppMenu
    items: [
      {
        label: mainMenuLabels.dashboard,
        icon: icons.dashboard,
        style: parentIconStyle,
        to: '/'
      },
      {
        label: mainMenuLabels.appointments,
        icon: icons.schedule,
        style: parentIconStyle,
        to: routes.appointments.path
      },
      // { label: mainMenuLabels.schedule, icon: 'pi pi-calendar-plus', to: '/scheduler' },
      {
        label: mainMenuLabels.patients,
        icon: icons.patient,
        style: parentIconStyle,
        to: routes.patients.path
      },
      {
        label: mainMenuLabels.money,
        icon: icons.dollar,
        style: parentIconStyle,
        to: routes.invoices.path
        // items: [
        //   { label: mainMenuLabels.invoices, icon: 'pi pi-paperclip', style: childIconStyle, to: routes.invoices.path },
        //   { label: mainMenuLabels.payments, icon: 'pi pi-money-bill', style: childIconStyle, to: routes.payments.path }
        // ]
      },
      ...(showRoles ? [{ label: mainMenuLabels.roles, icon: 'pi pi-id-card', style: parentIconStyle, to: routes.roles.path }] : []),
      { label: mainMenuLabels.staff, icon: 'pi pi-users', style: parentIconStyle, to: routes.staff.path },
      { id: 'memos', label: mainMenuLabels.memos, icon: icons.email, style: parentIconStyle, to: routes.claimNotesInbox.path },
      { label: mainMenuLabels.reports, icon: icons.reports, style: parentIconStyle, to: routes.reports.path },
      // { label: mainMenuLabels.mailbox, icon: 'pi pi-inbox', style: parentIconStyle, to: routes.mailbox.path },
      {
        label: mainMenuLabels.support,
        icon: icons.support,
        style: parentIconStyle,
        to: routes.support.path
      },
      // { label: mainMenuLabels.staff, icon: icons.stuff, style: parentIconStyle, to: '/staff' },
      {
        label: mainMenuLabels.legacy,
        icon: icons.legacy,
        style: parentIconStyle,
        command: () => {
          const totalOutstanding = getTotalOutstanding();
          if (totalOutstanding > 0) {
            store.dispatch(setShowTotalOutstandingDialog({ showDialog: true, type: 'switchToOldDesign' }));
          } else {
            onSwitchToOldDesign();
          }
        }
        // url: 'https://secure.claimmanager.ca/default.aspx'
      }
      // {
      //   label: mainMenuLabels.settings, icon: 'pi pi-cog', style: parentIconStyle, items: [
      //     { label: mainMenuLabels.billing, icon: 'pi pi-money-bill', style: childIconStyle, to: '/billing' },
      //     { label: mainMenuLabels.clinic, icon: 'pi pi-briefcase', style: childIconStyle, to: '/clinic' },
      //     { label: mainMenuLabels.insurers, icon: 'pi pi-th-large', style: childIconStyle, to: '/insurers' },
      //     { label: mainMenuLabels.teleplan, icon: 'pi pi-file-o', style: childIconStyle, to: '/teleplan' },
      //     { label: mainMenuLabels.audit, icon: 'pi pi-list', style: childIconStyle, to: '/audit' }
      //   ]
      // },
      // {
      //   label: "Demo", icon: 'pi pi-desktop', style: parentIconStyle, items: [
      //     { label: "Demo Table", icon: 'pi pi-table', style: childIconStyle, to: routes.tableView.path },
      //   ]
      // },
    ]
  }
];

const prodBetaMenu = [
  {
    items: mainDefaultMenu[0].items.filter((x) => {
      return x.label !== mainMenuLabels.appointments;
    })
  }
];

export const mainMenu = hostname.indexOf('claimmanager') > -1 ? prodBetaMenu : mainDefaultMenu;

export const createMenuSerenity = (user, logout) => {
  const reply = [
    {
      label: t('Dashboard'),
      icon: 'pi-home',
      scope: 'd',
      command: () => {
        window.location = '#/';
      }
    },
    {
      label: t('Schedule'),
      icon: 'pi-calendar-plus',
      scope: 's',
      command: () => {
        window.location = '#/scheduler';
      }
    },
    {
      label: t('Patient.3'),
      icon: 'pi-user',
      scope: 'p',
      command: () => {
        window.location = '#/patients';
      }
    },
    {
      label: t('Money'),
      icon: 'pi-dollar',
      scope: 'c',
      command: () => {
        window.location = `#${routes.invoices.path}`;
      }
    },
    {
      label: t('Reports'),
      icon: 'pi-chart-line',
      scope: 'r',
      command: () => {
        window.location = '#/reports';
      }
    },
    // { label: t('staff'), icon: 'pi-id-card', scope: 'u', command: () => { window.location = '#/staff' } },
    {
      label: t('Settings'),
      icon: 'pi-cog',
      scope: 'u',
      items: createSettingsMenu()
    }
  ];
  return reply;
};

const createSettingsMenu = () => {
  return [
    {
      label: t('Billing'),
      icon: 'pi-money-bill',
      scope: 'u',
      command: () => {
        window.location = '#/billing';
      }
    },
    {
      label: t('Clinic'),
      icon: 'pi-briefcase',
      scope: 'u',
      command: () => {
        window.location = '#/clinic';
      }
    },
    {
      label: t('Insurers'),
      icon: 'pi-th-large',
      scope: 'u',
      command: () => {
        window.location = '#/insurers';
      }
    },
    {
      label: t('Teleplan'),
      icon: 'pi-file-o',
      scope: 'u',
      command: () => {
        window.location = '#/teleplan';
      }
    },
    {
      label: t('Audit'),
      icon: 'pi-list',
      scope: 'u',
      command: () => {
        window.location = '#/audit';
      }
    }
  ];
};

//Header menu
export const createMenu = (specs) => {
  const reply = [
    { label: t('Gear'), items: createGearMenu() },
    { label: t('Account'), items: createUsersMenu(specs) }
  ];
  return reply;
};

const createGearMenu = () => {
  return [
    { label: routes.userPreferences.title, icon: 'pi-user-edit', scope: 'u' },
    { label: routes.clinicPreferences.title, icon: 'pi-th-large', scope: 'u' }
  ];
};

const createUsersMenu = (specs) => {
  const reply = [];
  reply.push({ label: t('Profile'), icon: 'pi-user', scope: 'u' });
  specs?.forEach((x) => {
    if (x) {
      const spec = getSpeciality(x);
      reply.push({ label: toTitleCase(spec.label), data: x, scope: 'u' });
    }
  });
  reply.push({ label: t('Change_password'), icon: 'pi-key', scope: 'u' });
  reply.push({ label: t('Logout'), icon: 'pi-sign-out', scope: 'u' });

  return reply;
};
