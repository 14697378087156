import React from 'react';
import { Button } from 'primereact/button';
import { icons } from '../../../../../../modules/config/stylesConfig';
import { useSimilarClaimsListContext } from '../../../hooks/useSimilarClaimsListContext';
import { t } from '../../../../../../service/localization/i18n';

const ArchiveButton = ({ rowData }) => {
  const { setState } = useSimilarClaimsListContext();

  return (
    <Button
      icon={icons.archived}
      label={t('Archive')}
      text
      onClick={() => setState((prevState) => ({ ...prevState, archiveDialog: { visible: true, claim: rowData } }))}
    />
  );
};

export default ArchiveButton;
