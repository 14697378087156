import React from 'react';
import { Button } from 'primereact/button';
import { InputSwitch } from 'primereact/inputswitch';

import { t } from '../../service/localization/i18n';

const PaginatorRight = ({
  filters,
  onExpand,
  isExpanded,
  onExportClick,
  setFieldFilter,
  showDataScroller,
  showExpandButton,
  showExportButton = false,
  showArchivedButton = false,
  isMobileDataTableView
}) => {
  return (
    <div className="flex justify-content-around align-items-center">
      {showArchivedButton && (
        <InputSwitch
          className="mr-2"
          checked={filters?.showArchived === 'True' || filters?.showArchived === true ? true : false}
          tooltip={t('Show_Archived')}
          tooltipOptions={{ position: 'top' }}
          onChange={(e) => setFieldFilter(e, 'showArchived')}
        />
      )}

      {showExpandButton && (showDataScroller || !isMobileDataTableView) && (
        <Button
          className="p-button-text"
          tooltip={isExpanded ? t('Collapse_all') : t('Expand_all')}
          tooltipOptions={{ position: 'top' }}
          icon={isExpanded ? 'pi pi-angle-up' : 'pi pi-angle-down'}
          onClick={onExpand}
        />
      )}

      {showExportButton && (
        <Button
          className="p-button-text"
          icon="pi pi-external-link"
          tooltip={t('Export')}
          tooltipOptions={{ position: 'top' }}
          onClick={onExportClick}
        />
      )}
    </div>
  );
};

export default PaginatorRight;
