import React, { useRef } from 'react';

import { Button } from 'primereact/button';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Calendar } from 'primereact/calendar';

import MonthNavigator from './MonthNavigator';
import YearNavigator from './YearNavigator';
import CalendarDateTemplete from './CalendarDateTemplete';

import { defaultYearRange } from '../../../modules/config/defaultValuesConfig';
import cx from 'classnames';

import './DatePicker.scss';

// interface = {
//  value: Date,
//  onChange: (value: Date) => void,

//  status?: number | string,
//  buttonClassName?: string,
//  yearRange?: string,
//  multipleDates?: boolean,
//  dateTemplate?: () => void,
// };

const DatePickerButton = ({ value, onChange, multipleDates = false, ...props }) => {
  const op = useRef(null);
  const yearRange = props.yearRange || defaultYearRange;

  const handleChange = (value) => {
    if (onChange) onChange(value);
    !multipleDates && op.current.hide();
  };

  const monthNavigatorTemplate = (e) => <MonthNavigator event={e} />;
  const yearNavigatorTemplate = (e) => <YearNavigator event={e} />;
  const dateTemplate = (data) => <CalendarDateTemplete data={data} />;

  return (
    <>
      <Button
        style={{ width: '24px', height: '24px' }}
        className={props.buttonClassName || 'p-button-outlined'}
        type="button"
        icon="pi pi-calendar"
        aria-controls="overlay_panel"
        aria-haspopup
        onClick={(e) => op.current.toggle(e)}
      />

      <OverlayPanel ref={op} showCloseIcon id="overlay_panel">
        <div className="flex flex-column">
          <Calendar
            className={cx('date_picker_button_overlay_calendar', `calendar_cm_status_${props.status}`)}
            showButtonBar
            value={value}
            selectOtherMonths
            inline
            selectionMode={multipleDates ? 'multiple' : 'single'}
            monthNavigator
            yearNavigator
            yearRange={yearRange}
            monthNavigatorTemplate={monthNavigatorTemplate}
            yearNavigatorTemplate={yearNavigatorTemplate}
            dateTemplate={dateTemplate}
            onChange={(e) => handleChange(e.value)}
            onClearButtonClick={props.onClearButtonClick}
          />
          <Button
            // className="w-min align-self-end"
            type="button"
            label="Done"
            aria-controls="overlay_panel"
            aria-haspopup
            onClick={(e) => op.current.toggle(e)}
          />
        </div>
      </OverlayPanel>
    </>
  );
};

export default DatePickerButton;
