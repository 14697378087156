import React from 'react';
import { Dropdown } from 'primereact/dropdown';

const MonthNavigator = ({ event }) => {
  return (
    <Dropdown
      className="mr-2"
      value={event.value}
      options={event.options}
      onChange={(e) => event.onChange(e.originalEvent, e.value)}
      style={{ lineHeight: 1, minWidth: '130px' }}
    />
  );
};

export default MonthNavigator;
