import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import { setShowTotalOutstandingDialog } from '../../../modules/claims/actions/claims.action.creators';
import { elementIDs } from '../../../modules/config/elementIDsConfig';
import { t } from '../../../service/localization/i18n';
import { getTotalOutstanding } from '../../../modules/utils/getTotalOutstanding';
import { logout, onSwitchToOldDesign } from '../../../modules/auth/actions/auth.actions.creators';

const TotalOutstandingDialog = () => {
  const dispatch = useDispatch();
  const showTotalOutstandingDialog = useSelector((state) => state.claims.showTotalOutstandingDialog);
  const totalOutstanding = getTotalOutstanding();
  const switchToOldDesign = showTotalOutstandingDialog.type === 'switchToOldDesign';

  const onClose = () => dispatch(setShowTotalOutstandingDialog({ showDialog: false, type: '' }));

  const onLogout = () => {
    if (switchToOldDesign) {
      onSwitchToOldDesign();
    } else {
      dispatch(logout());
    }

    onClose();
  };

  const actionButtons = (
    <>
      <Button id={elementIDs.dialogCancel} className="p-button-outlined" label={t('Close')} onClick={onClose} />
      <Button autoFocus id={elementIDs.dialogCancel} label={switchToOldDesign ? t('Switch back') : 'Logout'} onClick={onLogout} />
    </>
  );

  return (
    <Dialog
      header={t('Warning')}
      visible={showTotalOutstandingDialog.showDialog}
      breakpoints={{ '1366px': '50vw', '960px': '70vw', '768px': '90vw' }}
      style={{ width: '30vw' }}
      footer={actionButtons}
      onHide={onClose}
    >
      <p className="text-lg">{`Please note that you have ${totalOutstanding} outstanding claim(s). Remember to submit them.`}</p>
    </Dialog>
  );
};

export default TotalOutstandingDialog;
