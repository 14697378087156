import React, { useState, useEffect, useRef, Profiler, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom'; 
import { PrintInvoicePage } from './pages/PrintInvoicePage';
import { v4 as randomUUID } from 'uuid';

import Nav from './Nav';
import AppMenu from './AppMenu';
import AppTopbar from './AppTopbar';
import AppConfig from './AppConfig';
import ErrorBoundary from './ErrorBoundary';
import CookieConsent from 'react-cookie-consent';

import { BlockUI } from 'primereact/blockui';
import Loader from './components/Misc/Loader/Loader';
import Print from './components/Layouts/Print/Print';
import Toasts from './components/Dialogs/Toasts/Toasts';
import CommonDialogs from './components/Dialogs/Common/CommonDialogs';
import SessionIdle from './components/Dialogs/SessionIdle/SessionIdle';

import { routes } from './routes/routes';
import { getRedirectUrl } from './config';
import { mainMenu } from './modules/config/mainMenu';
import { logout, switchSpeciality, loginAsAffiliate, clearUserDetails, setLoginView, updateUser } from './modules/auth/actions/auth.actions.creators';
import { onThemeChange } from './modules/utils/onThemeChange';
import { updateWidnowSizes, setLayoutMode, setToastMessage } from './modules/core/actions/core.action.creators';

import cx from 'classnames';
import classes from './styles/App.module.scss';

import PrimeReact from 'primereact/api';
import 'primereact/resources/primereact.min.css';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import './App.scss';
import './service/localization/i18n';
import Auth from './modules/auth/components/Auth';
import { t } from './service/localization/i18n';
import { getTotalOutstanding } from './modules/utils/getTotalOutstanding';
import { setShowTotalOutstandingDialog } from './modules/claims/actions/claims.action.creators';
import { getTotalUnreadNotes } from './modules/notes/actions/actions';
import { resetState } from './store';
 
const App = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const user = useSelector((state) => state.user.details);
  const { loginAsAffiliateRequest } = useSelector((state) => state.user);
  const { appmode, layoutMode, theme } = useSelector((state) => state.core);

  const [loggedIn, setLoggedIn] = useState(false);
  const [activeTopbarItem, setActiveTopbarItem] = useState(null);
  const [mobileMenuActive, setMobileMenuActive] = useState(null);
  const [topbarMenuActive, setTopbarMenuActive] = useState(null);
  const [menuActive, setMenuActive] = useState(false);
  const [themeColor, setThemeColor] = useState('bluegrey');
  const [layoutColor, setLayoutColor] = useState('moody');
  const [darkMenu, setDarkMenu] = useState(false);
  const [blockUI, setBlockUI] = useState(false);
  const [inputStyle, setInputStyle] = useState('outlined');
  const [ripple, setRipple] = useState(true);
  const [colorMode, setColorMode] = useState('light');
  const bureauUser = sessionStorage.getItem('bau');
  const sidebar = useRef(null);

  PrimeReact.ripple = true;

  let menuClick;
  let menuButtonClick;
  let topbarMenuClick;
  let topbarMenuButtonClick;
  let sidebarTimeout;

  const handleLogout = () => {
    try {
      const tabId = sessionStorage.getItem('TAB_ID_KEY');
      const tabToken = `${tabId}_token`;
      sessionStorage.removeItem('TAB_ID_KEY');
      sessionStorage.removeItem(tabToken);
      sessionStorage.removeItem('keepSignedIn');
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    loggedIn && getTotalUnreadNotes();
  }, [loggedIn]);

  useEffect(() => {
    const handleLogoutOnUnload = () => {
      try {
        const keepSignedIn = sessionStorage.getItem('keepSignedIn'); // Check if keepSignedIn is in sessionStorage
        const keepSignedInCookie = document.cookie.includes('keepSignedIn=true'); // Check if keepSignedIn cookie is set

        // Check if keepSignedIn is present in sessionStorage or keepSignedIn cookie is set
        if (keepSignedIn || keepSignedInCookie) {
          return; // Don't perform logout action
        }

        handleLogout();
        const query = new URLSearchParams(location.search);
        const code = query.get('code'); 
        if (!!code) {
          window.location.href = '/';
        }
      } catch (error) {
        console.log(error);
      }
    };

    window.addEventListener('unload', handleLogoutOnUnload);

    return () => {
      window.removeEventListener('unload', handleLogoutOnUnload);
    };
  }, []);

  //=======SET THEME COLOR=======//
  // Update theme if redux value is changed. The initial redux value is taken from the local state. Works with 2nd useEffect below
  useEffect(() => {
    onThemeChange(theme, colorMode);
  }, [theme, colorMode]);
  //=================================================//

  // is logged in
  useEffect(() => {
    if (user) setLoggedIn(!!user.JwtToken);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  // window resizing
  useEffect(() => {
    updateWindowDimensions();
    window.addEventListener('resize', updateWindowDimensions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // componentWillUnmount
  useEffect(() => {
    return () => window.removeEventListener('resize', updateWindowDimensions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateWindowDimensions = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isMobile = !!/Android|webOS|iPhone|iPad|iPod|BB10|BlackBerry|IEMobile|Opera Mini|Mobile|mobile/i.test(navigator.userAgent || '');
    const isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
      userAgent
    );
    const orientation = (window.screen.orientation || {}).type || window.screen.mozOrientation || window.screen.msOrientation;
    const formatOrientation = orientation === 'landscape-primary' ? true : false;
    const isLandscape = formatOrientation !== undefined ? formatOrientation : window.innerHeight < window.innerWidth ? true : false;

    const newSizes = {
      isMobile: window.innerWidth < 640 || isMobile,
      isMobileDataTableView: isMobile,
      //isMobileDataTableView: window.innerWidth <= 1024 && isMobile,
      isTablet,

      isLandscape,
      isMobileOnly: isMobile && !isTablet,
      showNewTeleplanButtons: (isMobile && !isTablet) || (isTablet && !isLandscape),

      // Cypress tests only - mobile view
      // isLandscape: false,
      // isMobileOnly: true,

      width: window.innerWidth,
      height: window.innerHeight,
      clientWidth: document.documentElement.clientWidth,
      clientHeight: document.documentElement.clientHeight,
      isXL: !isMobile && document.documentElement.clientWidth > 1600,
      iPadProInLandscape: isTablet && isLandscape && document.documentElement.clientWidth > 1200,
      iPadInPortrait: isMobile && !isLandscape && document.documentElement.clientWidth < 1024 ? true : false //only iPad (not iPad Pro). Screen resolution: 768 x 1024
    };

    dispatch(updateWidnowSizes(newSizes));
  };

  const onInputStyleChange = (inputStyle) => {
    setInputStyle(inputStyle);
  };

  const onRipple = (e) => {
    PrimeReact.ripple = e.value;
    setRipple(e.value);
  };

  const onLayoutModeChange = (mode) => {
    dispatch(setLayoutMode(mode));
  };

  const onDarkMenuChange = (mode) => {
    if (mode === 'dark') {
      setDarkMenu(true);
      setColorMode('dark');
    } else {
      setDarkMenu(false);
      setColorMode('light');
    }
  };

  const onWrapperClick = (event) => {
    if (!menuClick && !menuButtonClick && mobileMenuActive) {
      setMobileMenuActive(false);
    }

    if (!topbarMenuClick && !topbarMenuButtonClick) {
      setActiveTopbarItem(null);
      setTopbarMenuActive(false);
    }

    if (!menuClick) {
      if (isHorizontal() || isOverlay()) {
        setMenuActive(false);
      }
    }

    menuClick = false;
    menuButtonClick = false;
    topbarMenuClick = false;
    topbarMenuButtonClick = false;
  };

  const onTopbarItemClick = async (event) => {
    topbarMenuClick = true;
    if (activeTopbarItem === event.item) {
      setActiveTopbarItem(null);
    } else {
      setActiveTopbarItem(event.item);
      event.originalEvent.preventDefault();

      // if (event.item === 'change_lng') {
      //   const lng = (i18n.language || 'en') === 'en' ? 'ru' : 'en';

      //   i18n.changeLanguage(lng).then(() => {
      //     this.setState({ menu: [...createMenuSerenity(this.state.user, logout)] });
      //   })
      // }

      if (event.item === 'audit') {
        history.push('/audit');
      }
      if (event.item === 'billing') {
        history.push('/billing');
      }
      if (event.item === 'clinic') {
        history.push('/clinic');
      }
      if (event.item === 'insurers') {
        history.push('/insurers');
      }
      if (event.item === 'teleplan') {
        history.push('/teleplan');
      }
      if (event.item.label === 'Profile') {
        history.push('/profile');
      }
      if (event.item.label === 'Settings') {
        history.push('/settings');
      }
      if (event.item === 'inbox') {
        history.push('/mailbox');
      }
      if (event.item === 'bureau') {
        setActiveTopbarItem(null);
        const bau = JSON.parse(bureauUser);
        const params = {
          source: 'SB',
          DCNGuid: bau.DCNGuid,
          UserGuid: bau.DoctorGuid,
          Key: bau.ImpersonateKey
        };

        const user = await loginAsAffiliate(params);
        dispatch(updateUser(user));
        // Navigate to dashboard
        history.push('/');
      }
      if (event.item.label === routes.userPreferences.title) {
        history.push(`${routes.userPreferences.path}`);
      }
      if (event.item.label === routes.clinicPreferences.title) {
        history.push(`${routes.clinicPreferences.path}`);
      }

      const specs = user.Specialties?.split('|');
      if (specs?.indexOf(event.item.data) > -1) {
        try {
          setBlockUI(true);
          await switchSpeciality(event.item.data);
          setBlockUI(false);
        } catch (error) {
          dispatch(
            setToastMessage({
              type: 'error',
              lifeTime: 1000,
              message: t('Something_went_wrong')
            })
          );
        }
      }
      if (event.item.label === t('Change_password')) {
        history.push(`${routes.changePassword.path}`);
      }
      if (event.item.label === 'Logout') {
        const totalOutstanding = getTotalOutstanding();
        if (totalOutstanding > 0) {
          dispatch(setShowTotalOutstandingDialog({ showDialog: true }));
        } else dispatch(logout());
      }
    }
  };

  const id = useMemo(randomUUID, []);

  useEffect(() => {
    const tabId = sessionStorage.getItem('TAB_ID_KEY');
    if (!tabId) {
      sessionStorage.setItem('TAB_ID_KEY', id);
    }
  }, [id]);

  const tabId = sessionStorage.getItem('TAB_ID_KEY');
  const currentPage = window.location.pathname;
  if (tabId === null) {
    if (currentPage === '/') {
      dispatch(resetState()); // Reset redux state
      clearUserDetails();
      dispatch(setLoginView('login'));
    } else {
      dispatch(logout());
    }
  }

  const onMenuButtonClick = (event) => {
    menuButtonClick = true;
    setMobileMenuActive((prevState) => !prevState);
    event.preventDefault();
  };

  const onTopbarMobileMenuButtonClick = (event) => {
    topbarMenuButtonClick = true;
    setTopbarMenuActive((prevState) => !prevState);
    event.preventDefault();
  };

  const onSidebarClick = (event) => {
    menuClick = true;
  };

  const onSidebarMouseEnter = (event) => {
    if (layoutMode !== 'horizontal') {
      setMenuActive(true);
    }
    if (sidebarTimeout) {
      clearTimeout(sidebarTimeout);
    }
    addClass(sidebar.current, 'layout-sidebar-active');
  };

  const onSidebarMouseLeave = (event) => {
    if (layoutMode !== 'horziontal') {
      setMenuActive(false);
    }
    sidebarTimeout = setTimeout(() => {
      removeClass(sidebar.current, 'layout-sidebar-active');
    }, 250);
  };

  const addClass = (element, className) => {
    if (element) {
      if (element.classList) element.classList.add(className);
      else element.className += ' ' + className;
    }
  };

  const removeClass = (element, className) => {
    if (element) {
      if (element.classList) element.classList.remove(className);
      else element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }
  };

  const onRootMenuItemClick = (event) => {
    setMenuActive((prevState) => !prevState);
  };

  const onMenuItemClick = (event) => {
    if (!event.item.items && isHorizontal()) {
      setMenuActive(false);
    }

    if (!event.item.items && isMobile()) {
      setMobileMenuActive(false);
      setMenuActive(false);
      onSidebarMouseLeave();
    }
  };

  const onToggleMenuClick = () => {
    dispatch(setLayoutMode(layoutMode !== 'static' ? 'static' : 'overlay'));
  };

  const changeTheme = (event) => {
    setThemeColor(event);
    changeStyleSheetUrl('theme-css', event, 'theme');
  };

  const changeLayout = (event) => {
    setLayoutColor(event);
    changeStyleSheetUrl('layout-css', event, 'layout');
  };

  const changeStyleSheetUrl = (id, value, prefix) => {
    let element = document.getElementById(id);
    let urlTokens = element.getAttribute('href').split('/');
    urlTokens[urlTokens.length - 1] = prefix + '-' + value + '.css';
    let newURL = urlTokens.join('/');

    replaceLink(element, newURL);
  };

  const isIE = () => {
    return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent);
  };

  const replaceLink = (linkElement, href) => {
    if (isIE()) {
      linkElement.setAttribute('href', href);
    } else {
      const id = linkElement.getAttribute('id');
      const cloneLinkElement = linkElement.cloneNode(true);

      cloneLinkElement.setAttribute('href', href);
      cloneLinkElement.setAttribute('id', id + '-clone');

      linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

      cloneLinkElement.addEventListener('load', () => {
        linkElement.remove();
        cloneLinkElement.setAttribute('id', id);
      });
    }
  };

  const isMobile = () => {
    return !!/Android|webOS|iPhone|iPad|iPod|BB10|BlackBerry|IEMobile|Opera Mini|Mobile|mobile/i.test(navigator.userAgent || '');
  };

  const isTablet = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);
  };

  const isMobileOnly = () => {
    return isMobile() && !isTablet();
  };

  const clientWidth = () => {
    return document.documentElement.clientWidth;
  };

  const isOverlay = () => {
    return layoutMode === 'overlay';
  };

  const isHorizontal = () => {
    return layoutMode === 'horizontal';
  };

  let wrapperClass = cx('layout-wrapper', {
    'layout-wrapper-static': layoutMode === 'static',
    'layout-wrapper-active': mobileMenuActive,
    'layout-menu-horizontal': layoutMode === 'horizontal',
    'p-input-filled': inputStyle !== 'filled',
    'p-ripple-disabled': true

    // default theme values
    // 'p-input-filled': inputStyle === 'filled',
    // 'p-ripple-disabled': !ripple
  });

  let sidebarClassName = cx('layout-sidebar', {
    'layout-sidebar-dark': darkMenu
  });
  const pageInfo = {
    DCNGuid: user?.details?.DCNGuid,
    Route: window.location.href,
    appmode
  };
  return (
    <ErrorBoundary {...pageInfo}>
      <BlockUI blocked={blockUI || loginAsAffiliateRequest}>
        {!loggedIn && document.location.href.indexOf('/print') === -1 && <Auth />}
        {loggedIn && user?.Role === 'bureau' && <Auth />}

        {!process.env.REACT_APP_CYPRESS && (
          <CookieConsent
            location="bottom"
            buttonText="Accept"
            cookieName="claimCookieConsent"
            style={{ background: '#333' }}
            buttonStyle={{ color: '#4e503b', fontSize: '13px' }}
            expires={150}
          >
            {t('Cookies_text')}
          </CookieConsent>
        )}

        <Print />

        <Loader />

        <Toasts />

        <CommonDialogs />

        {document.location.href.indexOf('/print-pdf/') > -1 && <PrintInvoicePage />}

        {loggedIn && user?.Role !== 'bureau' && document.location.href.indexOf('/print') === -1 && (
          <Profiler id="rrofilerId">
            <div className={wrapperClass} onClick={onWrapperClick}>
              {/* CMO-3068 - Add session idle timeout functionality */}
              <SessionIdle />

              <div
                tabIndex={-1}
                ref={sidebar}
                className={sidebarClassName}
                onClick={onSidebarClick}
                //  onMouseEnter={onSidebarMouseEnter} //do not open sidebar on mouse enter
                onMouseLeave={onSidebarMouseLeave}
              >
                <div
                  className={cx(
                    'sidebar-logo flex align-items-center pt-0',
                    isMobileOnly() ? classes.logoRootMobile : classes.logoRoot,
                    layoutMode === 'overlay' && !isMobileOnly() ? 'sidebar-logo justify-content-end pr-2' : 'justify-content-start'
                  )}
                >
                  <div>
                    <img
                      className={cx('mobile-logo-alt', isMobileOnly() ? classes.logoMobile : classes.logo)}
                      alt="logo"
                      src={'assets/layout/images/menu-logo.png'}
                    />
                  </div>

                  <div
                    className={
                      layoutMode === 'overlay' && (!isMobile() || (isTablet() && clientWidth() > 1025)) ? classes.logoWrap : classes.logoWrapHidden
                    }
                  >
                    <img
                      className={cx('mobile-logo-alt ml-4', isMobileOnly() ? classes.logoMobile : classes.logo)}
                      alt="logo"
                      src={'assets/layout/images/logo.png'}
                    />
                  </div>
                </div>

                <div className="layout-menu-container">
                  <AppMenu
                    model={mainMenu}
                    layoutMode={layoutMode}
                    active={menuActive}
                    onMenuItemClick={onMenuItemClick}
                    onRootMenuItemClick={onRootMenuItemClick}
                  />
                </div>
              </div>

              <div className="layout-main">
                <AppTopbar
                  layoutMode={layoutMode}
                  topbarMenuActive={topbarMenuActive}
                  activeTopbarItem={activeTopbarItem}
                  onTopbarItemClick={onTopbarItemClick}
                  onMenuButtonClick={onMenuButtonClick}
                  onToggleMenuClick={onToggleMenuClick}
                  onTopbarMobileMenuButtonClick={onTopbarMobileMenuButtonClick}
                />

                <div className={cx('layout-content pl-0 pr-0 pb-0', isMobileOnly() ? classes.mainContentMobile : classes.mainContent)}>
                  <Nav />
                </div>

                {/* <AppFooter /> */}

                {/* <AppConfig
              inputStyle={inputStyle}
              onInputStyleChange={onInputStyleChange}
              rippleEffect={ripple}
              onRippleEffect={onRipple}
              layoutMode={layoutMode}
              onLayoutModeChange={onLayoutModeChange}
              colorMode={colorMode}
              onDarkMenuChange={onDarkMenuChange}
              layoutColor={layoutColor}
              changeLayout={changeLayout}
              themeColor={themeColor}
              changeTheme={changeTheme}
            /> */}

                {mobileMenuActive && <div className="layout-main-mask"></div>}
              </div>
            </div>
          </Profiler>
        )}
      </BlockUI>
    </ErrorBoundary>
  );
};

export default App;
