import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Dashboard from './modules/dashboard/Dashboard';
import Appointments from './modules/appointments/Appointments';
import EditProfile from './modules/patients/components/PatientDetails/EditProfile';
import PatientDetails from './modules/patients/components/PatientDetails/PatientDetails';
import ReportsView from './modules/reports/components/Reports';
import ClaimList from './modules/claims/components/ClaimView';
import ClaimDetails from './modules/claims/components/ClaimDetails/ClaimDetails';
import ClaimNotesInbox from './modules/claims/components/ClaimNotesInbox/ClaimNotesInbox';
import PrivateDetails from './modules/claims/components/PrivateDetails/PrivateDetails';
import ReceivePayment from './modules/claims/components/ReceivePayment/ReceivePayment';
import ViewPanel from './modules/claims/components/ViewPanel';
import ViewPrivateInvoice from './modules/claims/components/ViewPrivateInvoice';
import ClinicView from './modules/clinic/components/clinic.view';
import BillingView from './modules/billing/components/billing.view';
import UserPreferences from './modules/preferences/components/UserPreferences';
import ClinicPreferences from './modules/preferences/components/ClinicPreferences';
import CreatePatient from './modules/patients/components/CreatePatient/CreatePatient';
import UserProfile from './modules/user/components/Profile/UserProfile';
import DemoDetailsView from './templates/DemoTable/components/demoDetailsView/DemoDetailsView';
import Practitioners from './modules/practitioners/components/PractitionersTable/Practitioners';
import EditPractitioner from './modules/practitioners/components/EditPractitioner/EditPractitioner';
import Patients from './modules/patients/components/Patients/Patients';
import WSBCReport from './modules/claims/components/WSBCReport/WSBCReport';
import EClaim from './modules/claims/components/eClaim/eClaim';
import EClaimPolicy from './modules/claims/components/EClaimPolicy/EClaimPolicy';
import WSBCReportPreview from './modules/claims/components/WSBCReport/modules/WSBCReportPreview/WSBCReportPreview';
import TeleplanInvoice from './modules/claims/components/TeleplanInvoice/TeleplanInvoice';
import ChangePasswordView from './modules/user/components/ChangePassword/ChangePasswordView';
import Mailbox from './modules/mailbox/components/Inbox/Inbox';
import Support from './modules/support/Support';
import Roles from './modules/roles/components/Roles/Roles';
import RoleDetails from './modules/roles/components/RoleDetails/RoleDetails';
import CreateEditRole from './modules/roles/components/CreateEditRole/CreateEditRole';
import StaffDetails from './modules/practitioners/components/PractitionersTable/StaffDetails';

import { NotFound } from './pages/NotFound';
import { Error } from './pages/Error';
import { routes } from './routes/routes';
import { PrintInvoicePage } from './pages/PrintInvoicePage';
import ClaimNoteDetails from './modules/claims/components/ClaimNotesInbox/ClaimNoteDetails/ClaimNoteDetails';
import PatientNotesInbox from './modules/patients/components/PatientNotesInbox/PatientNotesInbox';
import PatientNoteDetails from './modules/patients/components/PatientNotesInbox/PatientNoteDetails/PatientNoteDetails';
import Remittances from './modules/claims/components/Remittances';
import Messages from './modules/claims/components/Messages';

class Nav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      store: props.store,
      user: props.user,
      routing: props.routing
    };
  }

  render() {
    return (
      <main>
        <Switch>
          <Route exact path="/">
            <Dashboard />
          </Route>

          <Route exact path={`${routes.patientDetails.path}/:id`}>
            <PatientDetails />
          </Route>

          <Route exact path={`${routes.createPatient.path}/new`}>
            <CreatePatient />
          </Route>

          <Route exact path={`${routes.editProfile.path}/:id`}>
            <EditProfile />
          </Route>

          <Route exact path={`${routes.claimInfo.path}`}>
            <ViewPanel />
          </Route>

          <Route exact path={`${routes.claimProfile.path}/:id`}>
            <ClaimDetails />
          </Route>

          <Route exact path={`${routes.patientNotesInbox.path}`}>
            <PatientNotesInbox />
          </Route>

          <Route exact path={`${routes.patientNotesInbox.path}/view/:id`}>
            <PatientNoteDetails />
          </Route>

          <Route exact path={`${routes.patientNotesInbox.path}/reply`}>
            <PatientNoteDetails isReply={true} />
          </Route>

          <Route exact path={`${routes.patientNotesInbox.path}/edit/:id`}>
            <PatientNoteDetails isEdit={true} />
          </Route>


          <Route exact path={`${routes.remittances.path}`}>
            <Remittances />
          </Route>

          <Route exact path={`${routes.messages.path}`}>
            <Messages />
          </Route>



          <Route exact path={`${routes.remittances.path}`}>
            <Remittances />
          </Route>

          <Route exact path={`${routes.messages.path}`}>
            <Messages />
          </Route>



          <Route exact path={`${routes.remittances.path}`}>
            <Remittances />
          </Route>

          <Route exact path={`${routes.claimNotesInbox.path}`}>
            <ClaimNotesInbox />
          </Route>

          <Route exact path={`${routes.claimNotesInbox.path}/view/:id`}>
            <ClaimNoteDetails />
          </Route>

          <Route exact path={`${routes.claimNotesInbox.path}/reply`}>
            <ClaimNoteDetails isReply={true} />
          </Route>

          <Route exact path={`${routes.claimNotesInbox.path}/edit/:id`}>
            <ClaimNoteDetails isEdit={true} />
          </Route>

          <Route exact path={`${routes.privetInvoice.path}/:id`}>
            <ViewPrivateInvoice />
          </Route>

          <Route exact path={`${routes.staff.path}`}>
            <Practitioners />
          </Route>

          <Route exact path={`${routes.staffDetails.path}/:id`}>
            <StaffDetails />
          </Route> 

          <Route exact path={`${routes.ediPractitioner.path}/:id`}>
            <EditPractitioner />
          </Route>

          <Route exact path={`${routes.appointments.path}`}>
            <Appointments />
          </Route>

          <Route exact path={`${routes.demoDetailsView.path}`}>
            <DemoDetailsView />
          </Route>

          <Route exact path={`${routes.roles.path}`}>
            <Roles />
          </Route>

          <Route exact path={`${routes.roleDetails.path}/:id`}>
            <RoleDetails />
          </Route>

          <Route exact path={`${routes.createRole.path}`}>
            <CreateEditRole />
          </Route>

          <Route exact path={`${routes.editRole.path}/:id`}>
            <CreateEditRole isEdit={true} />
          </Route>

          <Route exact path={`${routes.profile.path}`}>
            <UserProfile />
          </Route>

          <Route exact path={`${routes.billing.path}`}>
            <BillingView />
          </Route>

          <Route exact path={`${routes.clinic.path}`}>
            <ClinicView />
          </Route>

          <Route exact path={`${routes.mailbox.path}`}>
            <Mailbox />
          </Route>

          <Route exact path={`${routes.reports.path}`}>
            <ReportsView />
          </Route>

          <Route exact path={`${routes.userPreferences.path}`}>
            <UserPreferences />
          </Route>

          <Route exact path={`${routes.support.path}`}>
            <Support />
          </Route>

          <Route exact path={`${routes.clinicPreferences.path}`}>
            <ClinicPreferences />
          </Route>

          <Route exact path={`${routes.patients.path}`}>
            <Patients />
          </Route>

          <Route exact path={`${routes.eClaimNew.path}`}>
            <EClaim />
          </Route>

          <Route exact path={`${routes.eClaimNew.path}/:patientGuid`}>
            <EClaim />
          </Route>

          <Route exact path={`${routes.eClaimEdit.path}/:claimGuid`}>
            <EClaim />
          </Route>

          <Route exact path={`${routes.eClaimView.path}/:claimGuid`}>
            <EClaim />
          </Route>

          <Route exact path={`${routes.eClaimPolicyNew.path}`}>
            <EClaimPolicy />
          </Route>

          <Route exact path={`${routes.eClaimPolicyNew.path}/:patientGuid`}>
            <EClaimPolicy />
          </Route>

          <Route exact path={`${routes.eClaimPolicyEdit.path}/:policyGuid/:patientGuid`}>
            <EClaimPolicy />
          </Route>

          <Route exact path={`${routes.changePassword.path}`}>
            <ChangePasswordView />
          </Route>

          <Route exact path="/money" component={ClaimList} />
          <Route path={routes.invoices.path} component={ClaimList} />

          <Route path={`${routes.claimRecordFirstVisit.path}`} component={TeleplanInvoice} />
          <Route path={`${routes.newClaimRecord.path}/:id?`} component={TeleplanInvoice} />
          <Route path={`${routes.teleplanPOS.path}/:id?`} component={TeleplanInvoice} />
          <Route path={`${routes.teleplanGroup.path}/:id?`} component={TeleplanInvoice} />
          <Route path={`${routes.editClaimRecord.path}/:id`} component={TeleplanInvoice} />

          <Route path={`${routes.newPrivateRecord.path}`} component={PrivateDetails} />
          <Route path={`${routes.privateRecord.path}/:id`} component={PrivateDetails} />

          <Route path={`${routes.wsbcReportPreview.path}/:id`} component={WSBCReportPreview} />
          <Route path={`${routes.newWSBCReport.path}`} component={WSBCReport} />
          <Route path={`${routes.wsbcReport.path}/:id`} component={WSBCReport} />

          <Route path={`${routes.receivePayment.path}/:id`} component={ReceivePayment} />
          <Route path={`${routes.editPayment.path}/:id`} component={ReceivePayment} />

          <Route exact path={`${routes.print_pdf.path}/:dcn/:id`}>
            <PrintInvoicePage />
          </Route>

          <Route exact path={routes.error.path}>
            <Error />
          </Route>

          <Route exact path="*">
            <NotFound />
          </Route>
        </Switch>
      </main>
    );
  }
}
export default Nav;
