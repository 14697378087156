import React from 'react';
import ArchiveDialog from './ArchiveDialog';

const Dialogs = () => {
  return (
    <>
      <ArchiveDialog />
    </>
  );
};

export default Dialogs;
