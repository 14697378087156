import { getListOfClaims } from '../../../../modules/claims/actions/claims.action.creators';
import { claimsDefaultSortBy } from '../../../../modules/config/defaultValuesConfig';
import { useSimilarClaimsListContext } from './useSimilarClaimsListContext';
import { defaultRequestParams } from '../helpers/defaultValues';

export const useTable = () => {
  const { state, setState } = useSimilarClaimsListContext();
  const currentRequestParams = {
    patientGuid: state.PatientGuid,
    page: state.page,
    pageSize: state.pageSize,
    query: state.query,
    sortby: state.sortby
  };

  const tableStyle = {
    minWidth: '30rem'
  };

  // Check for screen width and update style accordingly
  if (window.innerWidth >= 968) {
    tableStyle.minWidth = '60rem';
  }

  const fetchData = async (params = {}) => {
    setState((prevState) => ({ ...prevState, loading: true }));
    const requestParams = {
      ...defaultRequestParams,
      ...params
    };
    const results = await getListOfClaims(requestParams);
    if (results) {
      setState((prevState) => ({
        ...prevState,
        ...results,
        loading: false,
        selectedItem: state.claim
      }));
    } else {
      setState((prevState) => ({ ...prevState, loading: false }));
    }
  };

  const onSelectionChange = (e) => {
    setState((prevState) => ({ ...prevState, selectedItem: e.value }));
  };

  const onSort = (e) => {
    const sortOrders = { 1: 'asc', '-1': 'desc' };
    const sortby = e.sortOrder === 0 ? claimsDefaultSortBy.sortOrder : `${e.sortField}_${sortOrders[e.sortOrder]}`;
    fetchData({ ...currentRequestParams, sortby });
  };

  const onPage = (e) => {
    fetchData({ ...currentRequestParams, page: e.page + 1 });
  };

  return {
    tableStyle,
    currentRequestParams,
    onSort,
    onPage,
    fetchData,
    onSelectionChange
  };
};
