import React, { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Menu } from 'primereact/menu';

import HomeSearch from './HomeSearch';
import MSPEligibilityView from './MSPEligibilityView';
import SlashElement from '../../components/Misc/SlashElement/SlashElement';

import { routes } from '../../routes/routes';
import { elementIDs } from '../config/elementIDsConfig';
import { setToastMessage } from '../core/actions/core.action.creators';
import { addNewOverlayItemsList } from '../claims/helpers/addNewOverlayItemsList';
import { addEClaim, addNewPrivate, addNewTeleplan } from '../claims/helpers/addNewInvoice';
import { t } from '../../service/localization/i18n';

import './Dashboard.scss';

const Home = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.details);
  const { isMobileOnly, isLandscape, clientWidth, showNewTeleplanButtons } = useSelector((state) => state.core.window);
  const { isFetchingPHNValidation, isFetchingPatientEligibility } = useSelector((state) => state.patients);

  const [showMSPEligibilityDialog, setShowMSPEligibilityDialog] = useState(false);
  const addNewInvoiceMenuRef = useRef(null);
  const showMobileActionButtons = isMobileOnly && (!isLandscape || clientWidth < 767);

  const firstVisit = () => {
    addNewTeleplan({ history, firstVisit: true });
  };

  const newTeleplan = (pos = false) => {
    addNewTeleplan({ history, pos });
  };

  const newWSBCR = () => {
    history.push(`${routes.newWSBCReport.path}`);
  };

  const newPrivate = () => {
    addNewPrivate({ history, dispatch });
  };

  const newPatient = () => {
    history.push(`${routes.createPatient.path}/new`);
  };

  const invoiceThirdParty = () => {
    dispatch(setToastMessage({ type: 'info', message: 'Future Release', lifeTime: 3000 }));
  };

  const checkMSP = () => {
    setShowMSPEligibilityDialog(true);
  };

  const newEClaimPolicy = () => {
    history.push(`${routes.eClaimPolicyNew.path}`);
    // history.push(`${routes.eClaimPolicyNew.path}/3c5f1516-9aff-456b-b1ec-367e57e8727b`);
  };

  const newEClaim = () => {
    addEClaim({ history, patientGuid: '3c5f1516-9aff-456b-b1ec-367e57e8727b' });
  };

  const wsbcReportButton = () => {
    if (user.canBillWCB) {
      return (
        <Button id={elementIDs.createWSBCReportButton} className="p-button-rounded p-button-text w-max" label={t('WSBC_Report')} onClick={newWSBCR} />
      );
    }

    return null;
  };

  const mobileActionButtons = (
    <div className="flex justify-content-between w-full">
      {user.canBillMSP && (
        <>
          <Button
            id={elementIDs.newClaimButton}
            className="p-button-rounded p-button-text py-2 w-max"
            label={t('New_claim')}
            onClick={(e) => addNewInvoiceMenuRef.current.toggle(e)}
          />
        </>
      )}

      <Button id={elementIDs.addNewPatient} className="p-button-rounded p-button-text py-2 w-max" label={t('New_Patient')} onClick={newPatient} />

      <Button className="p-button-rounded p-button-text py-2 w-max" label={t('Check_MSP')} onClick={checkMSP} />
    </div>
  );

  const desktopActionButtons = (
    <div className="buttonsSectionsWrap">
      {/* Manage Patient */}
      <div className="buttonsSections">
        <div className="text-xl mb-5 font-bold">{t('Manage_Patients')}</div>

        <div className="flex flex-column align-items-center buttonsGap">
          <Button id={elementIDs.addNewPatient} className="p-button-rounded p-button-text py-2 w-max" label={t('New_Patient')} onClick={newPatient} />

          <Button className="p-button-rounded p-button-text py-2 w-max" label={t('Check_MSP')} onClick={checkMSP} />
        </div>
      </div>

      {/* Create Claim */}
      <div className="buttonsSections">
        <div className="text-xl mb-5 font-bold">{t('Create_claim_s')}</div>

        <div className="flex flex-column align-items-center buttonsGap">
          {user.canBillMSP && (
            <div className="flex">
              <Button
                id={elementIDs.createTeleplanInvoiceButton}
                className="p-button-rounded p-button-text py-2 w-max"
                label={t('Teleplan')}
                onClick={() => newTeleplan(false)}
              />

              {!showNewTeleplanButtons && (
                <>
                  <SlashElement />

                  <Button
                    id={elementIDs.createTeleplanInvoiceButton}
                    className="p-button-rounded p-button-text py-2 w-max"
                    label={t('Picker')}
                    onClick={() => newTeleplan(true)}
                  />

                  <SlashElement />

                  <Button
                    className="p-button-rounded p-button-text py-2 w-max"
                    label={t('Batch')}
                    onClick={() => history.push(routes.teleplanGroup.path)}
                  />
                </>
              )}

              <SlashElement />

              <Button
                id={elementIDs.createFirstVisitInvoiceButton}
                className="p-button-rounded p-button-text py-2 w-max"
                label={t('First_visit')}
                onClick={firstVisit}
              />
            </div>
          )}

          {wsbcReportButton()}
        </div>
      </div>

      {/* Create Invoice */}
      <div className="buttonsSections">
        <div className="text-xl mb-5 font-bold">{t('Create_Invoice')}</div>

        <div className="flex flex-column align-items-center buttonsGap">
          <Button
            id={elementIDs.createPrivateInvoiceButton}
            className="p-button-rounded p-button-text py-2 w-max"
            label={t('Patient.1')}
            onClick={newPrivate}
          />

          <Button className="p-button-rounded p-button-text py-2 w-max" label={t('third_party')} onClick={invoiceThirdParty} />
        </div>
      </div>
    </div>
  );

  return (
    <div className="pb-4">
      <Menu id="add_new_invoice_menu" popup ref={addNewInvoiceMenuRef} model={addNewOverlayItemsList({ history })} />

      {/* <Button
        label='EClaim'
        onClick={newEClaim}
      />

      <Button
        label='EClaim Policy'
        onClick={newEClaimPolicy}
      /> */}

      <HomeSearch>{showMobileActionButtons ? mobileActionButtons : desktopActionButtons}</HomeSearch>

      <Dialog
        visible={showMSPEligibilityDialog}
        header={t('MSP_Eligibility')}
        breakpoints={{ '1366px': '50vw', '960px': '70vw', '768px': '90vw' }}
        style={{ width: '30vw' }}
        onHide={() => setShowMSPEligibilityDialog(false)}
      >
        <MSPEligibilityView
          onHide={() => setShowMSPEligibilityDialog(false)}
          isFetchingPHNValidation={isFetchingPHNValidation}
          isFetchingPatientEligibility={isFetchingPatientEligibility}
        />
      </Dialog>
    </div>
  );
};

export default Home;
