import React from 'react';
import { useSelector } from 'react-redux';
import { TabPanel, TabView } from 'primereact/tabview';
import GlobalSearchInput from './GlobalSearchInput';
import { elementIDs } from '../config/elementIDsConfig';
import { t } from '../../service/localization/i18n';
import classes from './HomeSearch.module.scss';
import cx from 'classnames';

const HomeSearch = ({ children, inputClassName = '' }) => {
  const { isMobileOnly, isLandscape } = useSelector((state) => state.core.window);
  const inputWrapClass = cx('w-full', inputClassName, classes.inputSearch);

  return (
    <div id={elementIDs.homePage} className="flex flex-column align-items-center justify-content-center">
      {isMobileOnly && !isLandscape ? (
        <TabView className="col-12 p-0">
          <TabPanel header={t('Find_patient')}>
            <div id={elementIDs.homePageSearchContainer} className={inputWrapClass}>
              <GlobalSearchInput name="patient" placeholder={t('Enter_name_PHN_phone_or_other_patient_information_here')} />
            </div>

            {children && <div className="flex">{children}</div>}
          </TabPanel>

          <TabPanel header={t('Find_claim')}>
            <div className={inputWrapClass}>
              <GlobalSearchInput name="claim" placeholder={t('Enter_office_number_or_sequence_number_of_the_claim_here')} />
            </div>

            {children && <div className="flex">{children}</div>}
          </TabPanel>
        </TabView>
      ) : (
        <>
          <div id={elementIDs.homePageSearchContainer} className={inputWrapClass} style={{ maxWidth: '770px' }}>
            <GlobalSearchInput />
          </div>

          {children && <div className="flex">{children}</div>}
        </>
      )}
    </div>
  );
};

export default HomeSearch;
