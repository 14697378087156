import React from 'react';
import { Dropdown } from 'primereact/dropdown';

const YearNavigator = ({ event }) => {
  return (
    <Dropdown
      value={event.value}
      options={event.options?.reverse()}
      onChange={(e) => event.onChange(e.originalEvent, e.value)}
      className="p-ml-2"
      style={{ lineHeight: 1 }}
    />
  );
};

export default YearNavigator;
