/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Menu } from 'primereact/menu';
import { Badge } from 'primereact/badge';
import { Button } from 'primereact/button';

import TableHeaderMobile from './TableHeaderMobile';
import TableHeaderDesktop from './TableHeaderDesktop';

import { toggleFilters, toggleSorting } from '../../modules/core/actions/core.action.creators';
import { addNewItemToArray } from '../../modules/utils/addNewItemToArray';
import { t } from '../../service/localization/i18n';
import classes from './Table.module.scss';
import cx from 'classnames';

const TableHeader = React.forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const optionsMenuRef = useRef(null);
  const { showFiltersSidebar, showSortSidebar } = useSelector((state) => state.core);
  const showBadge = props.filters?.length > 0 && props.isMobile && props.header.filterButton.display;
  const tabletBreakpoint = window.innerWidth < 1025;

  const onFiltersClick = () => dispatch(toggleFilters(!showFiltersSidebar));
  const onSortClick = () => dispatch(toggleSorting(!showSortSidebar));

  const onSelect = () => {
    props.setShowCheckboxSelection(true);
    props.setIsMultipleSelect(true);
  };

  const onSelectAll = () => {
    props.setShowCheckboxSelection(true);
    props.setSelectedRow(props.dataTableValue);
    props.setIsMultipleSelect(true);
  };

  const onDelete = () => {
    props.deleteButton.onClick(props.selectedRow);
  };

  const optionsMenuModeProps = {
    dataTableRef: ref,
    dataTableValue: props.dataTableValue,
    dataTableName: props.dataTableName,
    recordsForExcel: props.recordsForExcel,
    columnsForPDF: props.columnsForPDF || props.selectedColumns,
    onSettingsClick: props.onSettingsClick
  };

  const moreOptionsMenuMode = () => {
    const filters = {
      label: t('Filters'),
      template: (item, options) => {
        return (
          <div style={{ position: 'relative' }}>
            <a className={options.className} target={item.target} onClick={onFiltersClick}>
              <span className={cx(options.iconClassName, 'pi pi-filter')}></span>
              <span className={options.labelClassName}>{item.label}</span>
            </a>

            {showBadge && <Badge className={classes.filtersBadge} value={props.filters?.length} severity="warning" />}
          </div>
        );
      }
    };

    const settings = {
      label: t('Settings'),
      icon: 'pi pi-cog',
      command: () => props.onSettingsClick()
    };

    const importList = {
      label: t('Export'),
      icon: 'pi pi-external-link',
      command: () => props.onExportClick()
    };

    let commonItems = [
      {
        label: t('Sort'),
        icon: 'pi pi-sort',
        command: onSortClick
      },

      // { separator: true },

      { separator: true },

      {
        label: t('Select'),
        icon: 'pi pi-check-circle',
        command: onSelect
      },

      {
        label: t('Select_all'),
        icon: 'pi pi-list',
        command: onSelectAll
      }
    ];

    // Show 'Filters' button
    if (props.header.filterButton.display) commonItems = addNewItemToArray(commonItems, 0, filters);

    // Show 'Settings' button
    if (!props.isMobile) {
      commonItems = addNewItemToArray(commonItems, commonItems.length, importList);
      commonItems = addNewItemToArray(commonItems, commonItems.length, { separator: true });
      commonItems = addNewItemToArray(commonItems, commonItems.length, settings);
    }

    return commonItems;
  };

  const selectedOptionsMenuMode = () => {
    const deleteSelectedItems = {
      label: t('Delete'),
      icon: 'pi pi-trash',
      command: () => onDelete()
    };

    let commonItems = [];

    if (props.header.deleteButton.display) {
      commonItems = addNewItemToArray(commonItems, commonItems.length, { separator: true });
      commonItems = addNewItemToArray(commonItems, commonItems.length, deleteSelectedItems);
    }

    return commonItems;
  };

  const moreOptionsButton = (
    <div style={{ position: 'relative' }}>
      <Menu id="options_popup_menu" ref={optionsMenuRef} popup baseZIndex={100000} model={moreOptionsMenuMode()} />
      <Button
        className={cx('ml-3', tabletBreakpoint ? 'p-button-rounded p-button-outlined' : 'p-button-text')}
        style={{ height: tabletBreakpoint ? '' : '40px' }}
        label={tabletBreakpoint ? '' : t('More')}
        type="button"
        icon="pi pi-ellipsis-v"
        aria-controls="options_popup_menu"
        aria-haspopup
        onClick={(e) => optionsMenuRef.current.toggle(e)}
      />

      {showBadge && <Badge className={classes.mobileTableOptionsBadge} value={props.filters?.length} severity="warning" />}
    </div>
  );

  const selectModeTemplate = (
    <div className="flex w-full">
      <div className="flex align-items-center mr-6">
        <Button
          className="p-button-rounded p-button-text mr-2"
          icon="pi pi-times"
          onClick={() => {
            props.setShowCheckboxSelection(false);
            props.setIsMultipleSelect(false);
            props.setSelectedRow([]);
          }}
        />
        {props.selectedRow?.length}
      </div>

      <div>
        {/* Select all button */}
        {props.isMobile && (
          <Button className="p-button-rounded p-button-text" icon="pi pi-list" onClick={() => props.setSelectedRow(props.dataTableValue)} />
        )}

        <Menu id="options_popup_menu" ref={optionsMenuRef} popup model={selectedOptionsMenuMode()} />
        <Button
          className={cx('ml-3 p-button-outlined', tabletBreakpoint ? 'p-button-rounded' : '')}
          type="button"
          label={tabletBreakpoint ? '' : t('More')}
          icon="pi pi-ellipsis-v"
          aria-controls="options_popup_menu"
          aria-haspopup
          onClick={(e) => optionsMenuRef.current.toggle(e)}
        />
      </div>
    </div>
  );

  return (
    <>
      <div className={classes.tableHeaderWrap}>
        <TableHeaderMobile
          {...props.header}
          filters={props.filters}
          dataTableValue={props.value}
          selectedRow={props.selectedRow}
          globalFilter={props.globalFilter}
          isMultipleSelect={props.isMultipleSelect}
          setSelectedRow={props.setSelectedRow}
          setIsMultipleSelect={props.setIsMultipleSelect}
          moreOptionsButton={moreOptionsButton}
          moreOptionsMenuMode={moreOptionsMenuMode}
          selectedOptionsMenuMode={selectedOptionsMenuMode}
        />

        <TableHeaderDesktop
          {...props.header}
          filters={props.filters}
          globalFilter={props.globalFilter}
          isMultipleSelect={props.isMultipleSelect}
          moreOptionsButton={moreOptionsButton}
          selectModeTemplate={selectModeTemplate}
          tabletBreakpoint={tabletBreakpoint}
          onChipRemove={props.onChipRemove}
          clearAllFilters={props.clearAllFilters}
        />
      </div>
    </>
  );
});

export default TableHeader;
