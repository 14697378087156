import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setDefaultClaimsTableValues, setClaimInfo, setClaimFilters, submitAllClaims } from '../claims/actions/claims.action.creators';
import ReviewYorSubmitDialog from './ReviewYorSubmitDialog';
import BatchRecordsWarnDialogForMultiplePatients from '../common/components/Dialogs/BatchRecordsWarnDialogForMultiplePatients';
import BatchRecordsWarnDialogForOnePatient from '../common/components/Dialogs/BatchRecordsWarnDialogForOnePatient';
import BatchRecordsSuccessDialogForMultiplePatients from '../common/components/Dialogs/BatchRecordsSuccessDialogForMultiplePatients';
import { Button } from 'primereact/button';
import { icons } from '../config/stylesConfig';
import { getEveryFirstLetterOfString } from '../utils/getEveryFirstLetterOfString';
import { filteredCounters } from './helpers/filteredCounters';
import { invoicesTypesLabels } from '../claims/helpers/mapper';
import { getClaimList } from '../claims/actions/claims.action.creators';
import { routes } from '../../routes/routes';
import { t } from '../../service/localization/i18n';
import classes from './Counters.module.scss';
import cx from 'classnames';
import { claimsDefaultFilters } from '../config/defaultValuesConfig';

const Counters = ({ isMobileOnly, isLandscape, showStatusBar = true }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { counters, filters, pageSize, sortby, isFetchingClaims, isSubmittingAllClaims } = useSelector((state) => state.claims);
  const [showReviewSubmitionDialog, setShowReviewSubmitionDialog] = useState(false);
  const [showReviewWarnDialogForOnePatients, setShowReviewWarnDialogForOnePatients] = useState(false);
  const [showReviewWarnDialogForMultiplePatients, setShowReviewWarnDialogForMultiplePatients] = useState(false);
  const [showReviewSubmitionSuccessDialog, setShowReviewSubmitionSuccessDialog] = useState(false);
  const [reviewSubmitionData, setReviewSubmitionData] = useState([]);

  const teleplanTotal = filteredCounters(counters, invoicesTypesLabels.teleplan)
    ?.map((i) => i.TotalFee)
    ?.reduce((a, b) => a + b, 0);
  const privateTotal =
    filteredCounters(counters, invoicesTypesLabels.patientPay)
      ?.map((i) => i.TotalFee)
      ?.reduce((a, b) => a + b, 0) + teleplanTotal;
  const status = (data) => {
    let suspectStatus = isMobileOnly && !isLandscape ? getEveryFirstLetterOfString(data.StatusCodeText) : data.StatusCodeText;
    if (data.StatusCodeText === 'Draft' && isMobileOnly && !isLandscape) suspectStatus = 'DR';

    return suspectStatus;
  };

  const countPrecent = (value, total) => {
    const percent = (100 * value) / total;
    return Math.round(percent * 100) / 100;
  };

  const onStatusClick = (newFilters) => {
    if (!isFetchingClaims) {
      const isInvoicesPage = history.location.pathname === routes.invoices.path;

      // CMO-251 - Counters -> click on any group->do not apply last 6 months filter
      const combinedFilters = { ...claimsDefaultFilters, ...newFilters };

      //reset highlighted element
      dispatch(setClaimInfo({}));

      //set default values for data table
      dispatch(setDefaultClaimsTableValues());

      //update filters
      dispatch(setClaimFilters(combinedFilters));

      if (isInvoicesPage) {
        //set getClaimList request if row item clicked on Invoices page
        dispatch(
          getClaimList({
            page: 1,
            pageSize: pageSize,
            filter: JSON.stringify(combinedFilters),
            query: `pid=undefined&sortby=${sortby}&q=${JSON.stringify(combinedFilters)}`
          })
        );
      } else {
        //if row item clicked anywhere in the application
        history.push(routes.invoices.path);
      }
    }
  };

  const showSection = (arr) => {
    return arr?.some((i) => i.TotalCount > 0);
  };

  const statusRow = (data, total) => {
    const rowKey = `d${data.StatusCodeText}${data.InvoiceType}`;
    return (
      <div
        className={cx('flex align-items-center justify-content-between col-12 py-2 px-0 pointer', classes.barChartWrap)}
        key={rowKey}
        onClick={() => onStatusClick({ StatusText: data.StatusCodeText, InvoiceType: data.InvoiceType })}
      >
        {showStatusBar ? (
          <>
            <div className={cx('flex justify-content-between', classes.totalCountWrap)}>
              <div>{status(data)}</div>
              <div className="mr-3">{data.TotalCount}</div>
            </div>

            <div className={`status-bar status-bar-${data.StatusCode}`}>
              <div className="status-bar-value" style={{ width: `${countPrecent(data.TotalFee, total)}%` }}>
                {/* {data.Total} */}
              </div>
            </div>
          </>
        ) : (
          <>
            <div className={classes.statusCodeTextHeaderRoot}>{data.StatusCodeText}</div>
            <div>{data.TotalCount}</div>
          </>
        )}

        <div className={cx('flex justify-content-end', showStatusBar ? classes.feeTotalWrap : '')}>{data.Total}</div>
      </div>
    );
  };

  const rowActionButtonParams = (status) => {
    switch (status) {
      case '0':
        return {
          icon: icons.submitOutstandingInvoice,
          tooltip: t('Submit_all_outstanding_claims'),
          command: () =>
            dispatch(
              submitAllClaims('preview', (data) => {
                setReviewSubmitionData(data);
                if (data?.TotalInValid > 0) {
                  // const isSingleIssue = data?.Issues[0]?.SingleIssue;
                  // isSingleIssue ? setShowReviewWarnDialogForOnePatients(true) : setShowReviewWarnDialogForMultiplePatients(true);

                  setShowReviewWarnDialogForMultiplePatients(true);
                } else {
                  setShowReviewSubmitionSuccessDialog(true);
                }

                //  setShowReviewSubmitionDialog(true); // Future releases
              })
            )
        };

      case '3':
        return {
          icon: icons.archived,
          tooltip: 'Archive test',
          command: () => console.log('!!!Archived')
        };

      default:
        break;
    }
  };

  const sections = [
    {
      label: t('Teleplan_claims'),
      filterBy: invoicesTypesLabels.teleplan,
      total: teleplanTotal,
      rootClass: ''
    },
    {
      label: t('Private_invoices'),
      filterBy: invoicesTypesLabels.patientPay,
      total: privateTotal,
      rootClass: 'mt-5'
    }
  ];

  return (
    <div className="col-12 p-0">
      {showSection(counters) ? (
        <div>
          {sections.map((section, index) => (
            <div key={index}>
              {showSection(filteredCounters(counters, section.filterBy)) && (
                <div className={section.rootClass}>
                  <div className="font-bold medium-size-text p-0 mb-2">{section.label}</div>
                  <div className="p-0 mt-0">
                    {filteredCounters(counters, section.filterBy)?.map((row) => {
                      const status = row.StatusCode;
                      const rowKey = `b${row.StatusCodeText}${row.InvoiceType}`;
                      return (
                        <div key={rowKey} className="flex align-items-center col-12 p-0">
                          <div className={cx('py-0 pl-0', showStatusBar ? 'col-11 pr-3' : 'col-10 pr-0')}>{statusRow(row, section.total)}</div>

                          <div className={cx('flex justify-content-center align-items-center p-0', showStatusBar ? 'col-1' : 'col-2')}>
                            {row.StatusCode === '0' && section.filterBy === 'Teleplan' && (
                              <Button
                                className={cx('p-button-text p-button-rounded', classes.rowActionButton)}
                                icon={rowActionButtonParams(status)?.icon}
                                tooltip={rowActionButtonParams(status)?.tooltip}
                                tooltipOptions={{ position: 'top' }}
                                loading={isSubmittingAllClaims}
                                onClick={rowActionButtonParams(status)?.command}
                              />
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      ) : (
        <div>{t('No_records_found')}</div>
      )}

      {/* Batch Success dialog */}
      <BatchRecordsSuccessDialogForMultiplePatients
        data={reviewSubmitionData}
        visible={showReviewSubmitionSuccessDialog}
        isFetching={isSubmittingAllClaims}
        updateClaimsList
        onHide={() => setShowReviewSubmitionSuccessDialog(false)}
        onReviewClick={(status, type) => onStatusClick(status, type)}
      />

      {/* Batch Warn dialog */}
      <BatchRecordsWarnDialogForMultiplePatients
        data={reviewSubmitionData}
        visible={showReviewWarnDialogForMultiplePatients}
        updateClaimsList
        onHide={() => setShowReviewWarnDialogForMultiplePatients(false)}
      />

      {/* For one patient */}
      <BatchRecordsWarnDialogForOnePatient
        data={reviewSubmitionData}
        visible={showReviewWarnDialogForOnePatients}
        showOnInvoicesPage={true}
        onHide={() => setShowReviewWarnDialogForOnePatients(false)}
      />

      {/* Future releases */}
      {/* <ReviewYorSubmitDialog
        data={reviewSubmitionData}
        visible={showReviewSubmitionDialog}
        isFetching={isSubmittingAllClaims}
        updateClaimsList
        onReviewClick={(status, type) => onStatusClick(status, type)}
        onHide={() => setShowReviewSubmitionDialog(false)}
      /> */}
    </div>
  );
};

export default Counters;
